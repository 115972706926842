import React, {useState, useEffect, useLayoutEffect, useRef, useContext} from 'react';
import {useLocation, NavLink, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../contexts/CognitoContext';
import {OverlayContext} from './../contexts/OverlayContext';

import {BLACKPEARL, BORDER, CHETWODEBLUE, OzButton} from "@ozwol/webui";

import ImpersonificationBar from "./layout/ImpersonificationBar";
import Sidebar from "./layout/Sidebar";
import Footer from "./layout/Footer";
import Overlay from "./layout/Overlay";
import ImportantOverlay from "./layout/ImportantOverlay";
import Popup from "./layout/Popup";
import ButtonWithAlert from "./ButtonWithAlert";

import ProjectsService from './../services/ProjectsService';
import HotfoldersService from './../services/HotfoldersService';
import AccountService from './../services/AccountService';

import {adminControl, checkPermission} from './../helpers';
import {POPUP} from './../config';

const COLUMN_WIDTH = "72px";
const SIDE_WIDTH = "256px";
const DETAIL_WIDTH = "515px";



const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  overflow: hidden;
  position: relative;
`;

const Hoverer = styled.div`
  & > div:last-child{
    left: ${props => !props.openSide && !props.fixSide ? "calc("+COLUMN_WIDTH+" - "+SIDE_WIDTH+")" : COLUMN_WIDTH};
  }
`;
const Column = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${COLUMN_WIDTH};
  height: 100%;
  z-index: 3;

  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-shrink: 0;
  background: #E9EBF4;
  border-right: 1px solid ${BORDER}80;
  text-align: center;
  padding: 20px 4px 8px;
  justify-content: space-between;

  user-select: none;
`; 
const Toggle = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;

  color: ${BLACKPEARL};
  &:hover{
    color: ${CHETWODEBLUE};
  }
`;
const ColumnGroup = styled.div`
`;
const ColumnGroupSeparator = styled.div`
  padding: 10px;
  border-bottom: 1px solid #00141f66;
`;


const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 6px;
  border-radius: 4px;
  margin-top: 15px;
  color: ${props => props.isActive ? CHETWODEBLUE : BLACKPEARL};
  border: 1px solid ${props => props.isActive ? BORDER : "transparent"};
  background: ${props => props.isActive ? "#ffffff" : "transparent"};
  box-shadow: ${props => props.isActive ? "0px 1px 2px #687FE239" : "none"};

  cursor: pointer;

  ${props => props.last ? "align-self: end;" : ""}

  &:hover{
    color: ${CHETWODEBLUE};
  }
  &:hover *{
    border-color: ${CHETWODEBLUE};
  }
`;
const ColumnItemIcon = styled.div`
  margin-bottom: -3px;
  & *{  
    font-size: 30px;
  }
`;
const ColumnItemText = styled.div`
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.3px
  position: relative;
  height: 10px;
  line-height: 10px;
`;
const Side = styled.div`
  position: absolute;
  top: 0px;
  left: ${props => props.fixSide ? COLUMN_WIDTH : "calc("+COLUMN_WIDTH+" - "+SIDE_WIDTH+")"};
  width: ${SIDE_WIDTH};
  height: 100%;
  z-index: 2;

  background: #F9F9FD;
  border-right: 1px solid ${BORDER}80;

  transition: all 200ms ease-in-out;
  user-select: none;
`;
const Content = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: calc(100vw - ${props => props.fixSide ? "calc("+COLUMN_WIDTH+" + "+SIDE_WIDTH+")" : COLUMN_WIDTH});
  height: 100%;
  z-index: 1;

  flex-grow: 1;
  flex-shrink: 1;
  background: #F6F7FB;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  overflow: hidden;

  transition: all 200ms ease-in-out;
`;

const ScrollerPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 25px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
`;
const Page = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const ScrollerDetail = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${DETAIL_WIDTH};
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
  box-shadow: 0 0 10px 1px #4466f20d;
`;
const Detail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

`;
const Foot = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 60px;
`;
const Avatar = styled.div`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  border: 2px solid ${props => props.active ? CHETWODEBLUE : BLACKPEARL};
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: middle;

  &::after{
    display: inline-block;
    text-align: center;
    content: "${props => props.label}";
    font-size: 18px;
    margin-top: 2px;
    margin-left: 1px;
  }
`;

const Layout = ({ section, detail, children }) => {
  const refScrollerPage = useRef();
  const refScrollerDetail = useRef();
  const location = useLocation();
  const [fixSide, setFixSide] = useState(true);
  const [openSide, setOpenSide] = useState(false);
  const [defaultProject, setDefaultProject] = useState(null);
  const [hotfolders, setHotfolders] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [period, setPeriod] = useState(null);
  const {cognito} = useContext(CognitoContext);
  const [user, setUser] = useState(null);
  const {overlay, setOverlay, importantOverlay, setImportantOverlay, popup, setPopup} = useContext(OverlayContext);
  
  // useLayoutEffect(() => { //TODO CHECK
  //   refScrollerPage.current.scrollTo(0, 0);
  // }, [location.pathname && !detail]);
  useLayoutEffect(() => {
    refScrollerDetail.current.scrollTo(0, 0);
  }, [location.pathname]);
  // document.documentElement.setAttribute('lang', lang);

  useEffect(() => {
    if(checkPermission("project", "list")){
      ProjectsService.list().then((response) => {
        setDefaultProject(response.result[0]);    
      });
    }
    // if(checkPermission("hotfolders", "list")){
      HotfoldersService.list().then((response) => {
        setHotfolders(response.result);    
      });
    // }
    if(cognito && cognito.groups && !cognito.groups.includes("OzwolCoworker")){ 
      AccountService.getUser().then((res) => {
        setUser(res);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [cognito]);

  useEffect(() => {
    // localStorage.removeItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
    if(!localStorage.getItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))){
      setPopup({
        "variant": "lilac",
        "title": "Stay Ahead with Ozwol",
        "fnClose": () => {
          setPopup(null);
          localStorage.setItem("ozwolConsolePopup"+POPUP+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), true);
        },
        "children": <>          
          Subscribe to Ozwol's newsletter to receive the latest updates directly in your inbox.<br/><br/>
          <a href="https://mailchi.mp/439dfc71a8d0/newsletter-subscription" target="_blank" rel="noreferrer"><OzButton variant={"chetwodeblue-o"}>Subscribe now</OzButton></a>
        </> 
      });
    } 
  }, [setPopup]);

  
  // useEffect(() => {
  //   if(checkPermission("period", "read")){
  //     AccountService.getPeriod().then((response) => {
  //       setPeriod(response);
  //     }).catch((error) => {
  //       setPeriod(null);
  //     });
  //   }
  // }, [section]);

  return (
    <Container>
      {adminControl(cognito) && section !== "insider" ? 
        <Top>
          <ImpersonificationBar />
        </Top>
      : null }
      <Wrapper>
        <Hoverer fixSide={fixSide} openSide={openSide} onMouseEnter={() => setOpenSide(true)} onMouseLeave={() => setOpenSide(false)}>
          <Column>
            <ColumnGroup>
              <Toggle onClick={() => {setFixSide(!fixSide); setOpenSide(false);}}>
                <span className="material-symbols-outlined">{fixSide ? "menu_open" : "menu"}</span>
              </Toggle>
              <NavLink to={"/overall/overview"} exact="false">
                {() => (
                  <ColumnItem isActive={pathname.includes('/overall')}>
                    <ColumnItemIcon><span className="material-symbols-outlined">mountain_flag</span></ColumnItemIcon>
                    <ColumnItemText>Overall</ColumnItemText>
                  </ColumnItem>
                )}
              </NavLink>
              {defaultProject && checkPermission("project", "list") ? 
                <NavLink to={"/project/"+(defaultProject ? defaultProject.uuid : null)+"/dashboard"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/project/')}>
                      <ColumnItemIcon><span className="material-symbols-outlined">business_center</span></ColumnItemIcon>
                      <ColumnItemText>Projects</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              :
                <NavLink exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/project/')} style={{opacity: "0.1"}}>
                      <ColumnItemIcon><span className="material-symbols-outlined">business_center</span></ColumnItemIcon>
                      <ColumnItemText>Projects</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              }
              <NavLink to={"/account"} exact="false">
                {() => (
                  <ColumnItem isActive={pathname.includes('/account')}>
                  <ColumnItemIcon><Avatar label={cognito.ParsedJwtToken["given_name"][0]} active={pathname.includes('/account')} /></ColumnItemIcon>
                    <ColumnItemText>Account</ColumnItemText>
                  </ColumnItem>
                )}
              </NavLink>
              {checkPermission("coworker", "list") && ((cognito && cognito.groups && cognito.groups.includes("OzwolCoworker")) || (user && user.currentPlan.teamAllowed)) ? 
                <NavLink to={"/team"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/team')}>
                      <ColumnItemIcon><span className="material-symbols-outlined">groups</span></ColumnItemIcon>
                      <ColumnItemText>Team</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              : null }
              {defaultProject && checkPermission("project", "list") && hotfolders && hotfolders.length > 0 ? 
                  <NavLink to={"/workspace/"+(defaultProject ? defaultProject.uuid : null)+"/hotfolders"} exact="false">
                    {() => (
                      <ColumnItem isActive={pathname.includes('/workspace')}>
                        <ColumnItemIcon><span className="material-symbols-outlined">cloud_upload</span></ColumnItemIcon>
                        <ColumnItemText>Workspace</ColumnItemText>
                      </ColumnItem>
                    )}
                  </NavLink>
                : null
                  // <NavLink exact="false">
                  //   {() => (
                  //     <ColumnItem isActive={pathname.includes('/workspace/')} style={{opacity: "0.1"}}>
                  //       <ColumnItemIcon><span className="material-symbols-outlined">cloud_upload</span></ColumnItemIcon>
                  //       <ColumnItemText>Workspace</ColumnItemText>
                  //     </ColumnItem>
                  //   )}
                  // </NavLink>
                }
              {adminControl(cognito) ? 
                <NavLink to={"/insider"} exact="false">
                  {() => (
                    <ColumnItem isActive={pathname.includes('/insider')}>
                    <ColumnItemIcon><span className="material-symbols-outlined">shield_person</span></ColumnItemIcon>
                      <ColumnItemText>Insider</ColumnItemText>
                    </ColumnItem>
                  )}
                </NavLink>
              : null}

              <ColumnGroupSeparator />
              <ButtonWithAlert
                title="Are you sure you want to logout?"
                onConfirm={() => navigate("/auth/logout")}
              >            
                <ColumnItem>
                  <ColumnItemIcon><span className="material-symbols-outlined">power_settings_new</span></ColumnItemIcon>
                  <ColumnItemText>Logout</ColumnItemText>
                </ColumnItem>
              </ButtonWithAlert>
            </ColumnGroup>
            
              
          </Column>
          <Side fixSide={fixSide}>
            <Sidebar section={section} />
          </Side>
        </Hoverer>
        <Content fixSide={fixSide}>
          <ScrollerPage ref={refScrollerPage}>
            <Page>
              {React.cloneElement(children, {refScrollerPage: refScrollerPage})}
            </Page>
            <Foot>
              <Footer />
            </Foot>
          </ScrollerPage>
          <ScrollerDetail ref={refScrollerDetail} style={{maxWidth: detail ? DETAIL_WIDTH : "0px"}}>
            {detail ?
              <Detail>
                {detail}
              </Detail>
            : null }
          </ScrollerDetail>

          { popup ? 
            <Popup item={popup} />
          : null }
        </Content>
        { overlay ? 
          <Overlay fnClose={() => setOverlay(null)}>
            {overlay}
          </Overlay>
        : null }

        { importantOverlay ? 
          <ImportantOverlay fnClose={() => setImportantOverlay(null)}>
            {importantOverlay}
          </ImportantOverlay>
        : null }
      </Wrapper>
    </Container>
  )
}

export default Layout;
