import React from 'react';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';

import {OzCard} from "@ozwol/webui";

const Message = styled.div`
  height: 30px;
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

const Error = ({children}) => {
	return (
        <OzCard>
          <Message>
            <ErrorMessage>
              {children}
            </ErrorMessage>
          </Message>
        </OzCard>
	)
}

export default Error;
