import ApiCore from './ApiCore';
import Typesense from 'typesense';

import {getUnixDate} from '../helpers';

class TypesenseService extends ApiCore {

  list(query = null, filter, page=1) {

    let client = new Typesense.Client(JSON.parse(process.env.REACT_APP_TYPESENSE_CONFIG));

    let filterString = "";
    if(filter.user_uuid){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "user_uuid:=" + filter.user_uuid;
    }
    if(filter.project_uuid){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "project_uuid:=" + filter.project_uuid;
    }
    if(filter.start){
      if(filterString.length > 0){
        filterString += "&&";
      }
      let start = new Date(filter.start);
      let timezoneOffset = start.getTimezoneOffset();
      if (timezoneOffset > 0) {
        start.setMinutes((24 * 60) - (timezoneOffset + 1));
      } else {
        start.setMinutes(-timezoneOffset); // Do not forget the negative sign !
      }
      filterString += "create_timestamp:>=" + (getUnixDate(start)/1000);
    }
    if(filter.end){
      if(filterString.length > 0){
        filterString += "&&";
      }
      let end = new Date(filter.end);
      let timezoneOffset = end.getTimezoneOffset();
      if (timezoneOffset > 0) {
        end.setMinutes((24 * 60) - (timezoneOffset + 1));
      } else {
        end.setMinutes(-timezoneOffset); // Do not forget the negative sign !
      }
      filterString += "create_timestamp:<=" + (getUnixDate(end)/1000);
    }
    if(filter.result){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "result:=" + filter.result;
    }
    if(filter.error_scope){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "error_scope:=" + filter.error_scope;
    }
    if(filter.consumer_type){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "consumer_type:=" + filter.consumer_type;
    }
    if(filter.origin){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "origin:=" + filter.origin;
    }
    if(filter.script_uuid){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "script_uuid:=" + filter.script_uuid;
    }
    if(filter.creditMin){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "credit_price:>=" + parseFloat(filter.creditMin);
    }
    if(filter.creditMax){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "credit_price:<=" + parseFloat(filter.creditMax);
    }
    if(filter.destination_type){
      if(filterString.length > 0){
        filterString += "&&";
      }
      filterString += "destination_type:=" + filter.destination_type;
    }

    let searchParameters = {
      'q': query ? query : '*',
      'query_by': 'source_file_key',
      'filter_by': filterString,
      'limit': 100, //250
      'per_page': 100, //250
      // 'group_by': 'siglaCampione',
      // 'sort_by': 'siglaMacrolotto:asc,siglaSondaggio:asc,siglaCampione:asc',
      'page': page

    }
    
    return new Promise((resolve, reject) => {

      client.collections(process.env.REACT_APP_TYPESENSE_COLLECTION)
      .documents()
      .search(searchParameters)
      .then(searchResults => {
        resolve(searchResults); 
      });
    });

  }


  listLists() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/logEnums').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

const service = new TypesenseService()
export default service;
