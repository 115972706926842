import React from 'react';
import styled from 'styled-components';

import {SILVERTREE, OzRow, OzCol} from "@ozwol/webui";

const Cnt = styled.div`
  margin-top: 20px;
  // min-height: 30px;
  // padding-top: 40px;
  // margin-top: -30px;

`;
const Text = styled.div`
  color: ${SILVERTREE};
  background: ${SILVERTREE}33;
  padding: 6px 10px;
`;

const SuccessMessage = ({noMargin = false, children}) => {
  if(noMargin){
    return (
      <>
        {children ? 
          <Text>
            {children}
          </Text>
        : null }
      </>  
    )

  }else{
    return (
      <OzRow>
        <OzCol>
          <Cnt>
            {children ? 
              <Text>
                {children}
              </Text>
            : null }
          </Cnt>
        </OzCol>
      </OzRow>
    )

  }
}

export default SuccessMessage;
