import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;

const FormEmailValidation = ({sessionUser, onConfirm, error}) => {
  const [email, setEmail] = useState(sessionUser && sessionUser.email ? sessionUser.email : '');
  const [code, setCode] = useState('');


  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Email validation</OzTypo>
      <>
        {sessionUser.givenName ? 
          <>Hi {sessionUser.givenName}.<br /></>
        : null }  
        Validate your email.
      </>

      <OzRow>
        <OzCol widthmd="12">
          <OzInput
            label={"Email"}
            name="email"
            type="text"
            width="100%"
            value={email}
            onChange={(val) => setEmail(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Code"}
            name="code"
            type="text"
            width="100%"
            value={code}
            onChange={(val) => setCode(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => onConfirm({
            action: "email-validation",
            params: {
              email: email,
              code: code
            }
          })}>Confirm</OzButton>
        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormEmailValidation;
