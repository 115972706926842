import React from 'react';
import styled from 'styled-components';

import { CHETWODEBLUE, BORDER } from '@ozwol/webui';

const Wrapper = styled.div`
  display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: calc(100% - 160px);
	margin: 0 auto;
	padding-top: 40px;
	padding-bottom: 30px;
`;
const Dot = styled.div`
	position: relative;
  width: 10px;
	height: 10px;
	border-radius: 20px;
	background-color: ${props => props.active ? CHETWODEBLUE : BORDER};
	flex-grow: 0;
	flex-shrink: 0;

	&::after{
		content: "${props => props.label}";
		display: block;
		position: absolute;
		width: 110px;
		top: 15px;
		left: -45px;

		text-align: center;		
		font-size: 9px;
		line-height: 10px;
		color: ${props => props.active ? CHETWODEBLUE : BORDER};

	}
`;
const Line = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	border-top: 2px dotted ${props => props.active ? CHETWODEBLUE : BORDER};
	height: 1px;
`;


const MfaWizard = ({step}) => {
  return (
    <Wrapper>
			<Dot active={step >= 1} label={"Verify your identity"} />
			<Line active={step >= 2} />
			<Dot active={step >= 2} label={"Scan the QR Code"} />
			<Line active={step >= 3} />
			<Dot active={step >= 3} label={"Enter the Code"} />
		</Wrapper>
  )
}

export default MfaWizard;
