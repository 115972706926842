import axios from 'axios';
import CognitoService from './CognitoService';
import {queryParams} from '../helpers';

class ImpersonificationService {

  constructor() {
    const baseURL = process.env.REACT_APP_API_ENDPOINT;
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.instance.interceptors.request.use(
      function(config) {
        let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
        if(new Date() >= new Date((user["ParsedJwtToken"]["exp"]*1000))){
          if(user.RefreshToken){
            return CognitoService.refresh(user.RefreshToken).then(response => {       
              user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));     
              if (user) {
                config.headers["Content-Type"] = 'application/json';
                config.headers["Authorization"] = user.AccessToken;

                return config;
              }else{
                window.location.replace("/auth/login");
              }
            }).catch(error => {
              window.location.replace("/auth/login");
            });
          }else{
            window.location.replace("/auth/login");
          }
        }else{
          if (user) {
            config.headers["Content-Type"] = 'application/json';
            config.headers["Authorization"] = user.AccessToken;

            return config;
          }else{
            window.location.replace("/auth/login");
          }
        }
        return null;
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/users'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service = new ImpersonificationService();
export default service;
