import React from 'react';
import styled from 'styled-components';

import {OzRow, OzCol} from "@ozwol/webui";

const Wrapper = styled.div`
  text-align: left;
  ${props => !props.first ? `
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #dedede;
  ` : null}
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-top: 5px;
`;
const Subtitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #303030B0;
  margin-top: 5px;
`;

const CardHeaderTitle = ({first = true, title, subtitle}) => {
	return (
		<Wrapper first={first}>
			<OzRow>
				<OzCol withmd="12">
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
				</OzCol>
			</OzRow>
		</Wrapper>
	)
}

export default CardHeaderTitle;
