import React, {useState} from 'react';

const CognitoContext = React.createContext();

const CognitoContextProvider = ({children}) => {
  const [cognito, setCognito] = useState(null);

  return (
    <CognitoContext.Provider value={{cognito, setCognito}}>
      {children}
    </CognitoContext.Provider>
  )
}

export {CognitoContextProvider, CognitoContext}
