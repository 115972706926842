import React from 'react';

import {CHETWODEBLUE, OzCard, OzLoading, OzTypo} from "@ozwol/webui";

const FormSuccess = ({onConfirm}) => {

  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Success</OzTypo>
      <>Well done.</>
      <br/>
      <center>
        <OzLoading />      
      </center>
    </OzCard>
  )
}

export default FormSuccess;
