import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../../../contexts/OverlayContext';

import {LILAC, OzButton} from "@ozwol/webui";

import WorkspaceService from './../../../services/WorkspaceService';
 
import UploadPolling from './UploadPolling';

const Form = styled.form`
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;

	& input{
		display: none;
	}
`;

const Label = styled.label`
	height: 100%;
	// background-color: ${props => props.dragActive ? "#ffffff" : "#f8fafc"};

`;
const Cover = styled.button`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	border: 0;
	background-color: ${LILAC}99;
`;
const UploadButton = styled.div`
	margin-top: 4px;

  opacity: 0;
  
  div:hover > form > &{
    opacity: 1;
  }

	& > button > span{
		font-size: 20px;
		line-height: 24px;
	}
`;

const traverseFileTree = (upload, item, path) => {
  path = path || "";
  if (item.isFile) {
    item.file(function(file) {
		upload([file], path);
    });
  } else if (item.isDirectory) {
    var dirReader = item.createReader();
    dirReader.readEntries(function(entries) {
      for (var i=0; i<entries.length; i++) {
        traverseFileTree(upload, entries[i], path + item.name + "/");
      }
    });
  }
}

const FileUpload = ({fullPath, refreshList, openPaths, setOpenPaths, children}) => {
  const {setPopup} = useContext(OverlayContext);
  const [dragActive, setDragActive] = useState(false);
	const inputRef = React.useRef(null);

  const dragActiveRef = React.useRef(false);
  useEffect(() => {
    dragActiveRef.current = dragActive;
  }, [dragActive]);
	
// useEffect(() => {
// }, [inputRef]);

  const handleDrag = function(e) {
		let timeout;
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
			
			if(!openPaths.includes(fullPath)){
				timeout = setTimeout(() => {	
					if(dragActiveRef.current){
						setOpenPaths([...openPaths, fullPath]);
					}
				}, 500);
				return () => {
					clearTimeout(timeout);
				};
			}
    } else if (e.type === "dragleave") {
			setDragActive(false);
			clearTimeout(timeout);
    }
  };

	const handleDrop = function(e) {
		if(!openPaths.includes(fullPath)){
			setOpenPaths([...openPaths, fullPath]);
		}
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		// console.log(e.dataTransfer);

		var items = e.dataTransfer.items;
		for (var i=0; i<items.length; i++) {
			var item = items[i].webkitGetAsEntry();
			if (item) {
				traverseFileTree(upload, item);
			}
		}

		// if (e.dataTransfer.files && e.dataTransfer.files[0]) {
		// 	upload(e.dataTransfer.files);
		// }
	};

	const handleChange = function(e) {
		e.preventDefault();
		if (e.target.files) {
			
			upload(e.target.files);
		}
	};

	
  const upload = (files, path = null) => {
		// console.log("UPLOAD: "+ files.length);
		// let promises = [];
		setPopup({
			"variant": "lilac",
			"title": "Uploading..."
		});
    for (var i = 0; i < files.length; i++) { 
      if(files[i].webkitRelativePath){
        let folders = files[i].webkitRelativePath.replace("/"+files[i].name, "").split("/");
        for (var j = 0; j < folders.length; j++) { 
          // WorkspaceService.createFolder(path.length > 0 ? path+"/" : "", folders.slice(0, j+1).join("/"));
        }
      }

      if(files[i].size === 0){
        // WorkspaceService.createFolder(path.length > 0 ? path+"/" : "", files[i].name, files[i]);
      }else{
				// promises.push(WorkspaceService.upload(fullPath.length > 0 ? fullPath+"/" : "", files[i].webkitRelativePath ? files[i].webkitRelativePath : files[i].name, files[i]));
				WorkspaceService.upload(fullPath.length > 0 ? fullPath+"/" : "", files[i].webkitRelativePath ? files[i].webkitRelativePath : path ? path+files[i].name : files[i].name, files[i]).then(() => {
					refreshList();
					setPopup({
						"variant": "lilac",
						"title": "Uploading...",
						"children": <UploadPolling />
					});
				});
      }
    }

		// Promise.all(promises).then((values) => {
		// 	console.log(values.length + " === " + files.length);
		// 	if(values.length === files.length){
				
		// 		console.log("refresh");
		// 		refreshList();
		// 	}
		// 	// refreshList();
		// });
  };
	

	return (
    <Form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
		<input 
			ref={inputRef} 
			type="file" 
			onChange={handleChange} 
			multiple
		/>
      <Label>
				{children}
				
      </Label>
			<UploadButton>
				<OzButton 
					variant="push-black" 
					size="small" 
					onClick={(e) => {
						if (inputRef.current !== null) {
								inputRef.current.removeAttribute("directory", "");
								inputRef.current.removeAttribute("webkitdirectory", "");
								inputRef.current.click();
								setOpenPaths([...openPaths, fullPath]);
						}
					}}
				>
					<span className="material-symbols-outlined">upload_file</span> Files
				</OzButton>
			</UploadButton>
			&nbsp;&nbsp;
			<UploadButton>
				<OzButton 
					variant="push-black" 
					size="small" 
					onClick={(e) => {
						if (inputRef.current !== null) {
							inputRef.current.setAttribute("directory", "");
							inputRef.current.setAttribute("webkitdirectory", "");
							inputRef.current.click();
							setOpenPaths([...openPaths, fullPath]);
						}
					}}
				>
					<span className="material-symbols-outlined">drive_folder_upload</span> Folders
				</OzButton>
			</UploadButton>

			{ dragActive && <Cover onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></Cover> }
    </Form>
	)
}

export default FileUpload;
