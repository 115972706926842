import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import { getFormattedDate, getArrayFromInterval, isAccountGoingToBeClosed, isSameDate, checkPermission } from "./../helpers";

import {SILVERTREE, CHETWODEBLUE, BITTERSWEET, OzButton} from "@ozwol/webui";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
`;
const Right = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;

const TimeLabel = styled.div`
  font-size: 13px;
  color: #303030;
  margin-bottom: 8px;
`;
const TimeValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #303030;
`;

// const VoidLine = styled.div`
//   position: relative;
//   /*border-bottom: 3px solid ${props => props.deleting ? BITTERSWEET : "#EBEBEB"};
//   height: 0px;*/
// `;
// const PastLine = styled.div`
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: ${props => props.width > 100 ? 100 : props.width}%;
//   height: 0px;
//   border-bottom: 3px solid ${CHETWODEBLUE};
// `;
// const FutureLine = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   width: ${props => props.width > 100 ? 0 : 100 - props.width}%;
//   height: 0px;
//   border-bottom: 3px solid ${props => props.deleting ? BITTERSWEET : "#EBEBEB"};
// `;
// const Today = styled.div`
//   position: absolute;
//   top: -34px;
//   left: ${props => props.position}%;
//   transform: translateX(-50%);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;
// const TodayText = styled.div`
//   color: ${SILVERTREE};
//   font-size: 10px;
//   text-transform: uppercase;
//   font-weight: 400;
// `;
// const TodayDot = styled.div`
//   background-color: ${SILVERTREE};
//   width: 3px;
//   height: 32px;
//   margin-top: 2px;
//   border-radius: 16px;
// `;

const PlanInfo = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  `;
const PlanLabel = styled.span`
  color: ${CHETWODEBLUE};
  margin-right: 5px;
  font-weight: 500;
`;
const NextPlanLabel = styled.span`
  color: #707070;
  margin-right: 10px;
  font-weight: 700;
`;
const PlanTag = styled(Link)`
  color: ${CHETWODEBLUE};
  font-weight: 500;
  text-decoration: underline !important;
  & *{
    cursor: pointer;
  }
`;
const ChangePlanNotification = styled.div`
  display: inline-block;
  color: #FF4848;
  background: #FF484815;
  font-size: 12px;
  font-weight: 700;
  padding: 2px 7px;
  margin-top: 4px;
  border-radius: 4px;
`;
const TimelineGrid = styled.div`
  display: grid;
  gap: ${props => props.space}px;
  grid-template-columns: repeat(${props => props.size},1fr);
  grid-template-rows: 1fr;

`;
const TimelineDay = styled.div`
  position: relative;
  background: ${props => props.color};
  color: ${props => props.color};
  height: 3px;
  min-width: 1px;
`;
const RechargeInfo = styled.div`
  position: relative;
  margin-top: -8px;
  
  & > span{
    position: relative;
    font-size: 18px;
    z-index: 3;
  }
  &:before{
    position: absolute;
    top: 8px;
    left: calc(calc(100% - 12px) / 2);
    width: 12px;
    height: 3px;
    content: "";
    display: block;
    background: #ffffff;
  }
`;
const RechargeInfoCounter = styled.div`
  color: #000000;
  font-size: 10px;
`;
const TodayTag = styled.div`
  position: absolute;
  width: 50px;
  left: 50%;
  top: -25px;
  margin-left: -25px;
  text-align: center;
  color: ${SILVERTREE};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
`;



const ReferencePeriodTimeline = ({
  period = null,
  user = null,
  plansPage = false,  
  reopenButton = null,
  showCta = true
}) => {
  if(period && period.currentPlan && period.currentPlan.planType === "COMPLIMENTARY"){
    // let start = 0;
    // let current = period.creditsConsumed;
    // let end = period.creditsRecharged;
    
    return (<>
      {/* <Wrapper>
        <Center>
          <VoidLine>
            <PastLine width={(current - start) * 100 / (end - start)}/>
            <FutureLine deleting={false} width={(current - start) * 100 / (end - start)}/>
            <Today position={(current - start) * 100 / (end - start)}>
              <TodayText>NOW</TodayText>
              <TodayDot></TodayDot>
            </Today>            
          </VoidLine>
        </Center>
        <Right>
          <TimeLabel>Tot credits</TimeLabel>
          <TimeValue>{end}</TimeValue>
        </Right>
      </Wrapper>
      <hr/> */}
      <Wrapper>
        <Left>
          <PlanInfo>
            <PlanLabel>
              {new Date(period.endDate) > new Date() ? 
                "Current plan"
              :
                "Period plan"
              }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
          </PlanInfo>
        </Left>
      </Wrapper>
    </>);

  }else if(period && period.currentPlan && period.currentPlan.planType === "PAID"){
    
    if(period.currentPlan.periodDuration !== "MONTHLY"){
      let start = new Date(period.startDate);
      let end = new Date(period.endDate);
      let today = new Date(Date.now());

      return (<>
        <Wrapper>
          <Left>
            <TimeLabel>Period start</TimeLabel>
            <TimeValue>{getFormattedDate(start)}</TimeValue>
          </Left>
          <Center>
            <TimelineGrid size={getArrayFromInterval(start, end).length} space={0}>
              {getArrayFromInterval(start, end).map(day => 
                <TimelineDay
                  key={day}
                  color={
                    isSameDate(day, today, "d") ? SILVERTREE : //today
                    new Date(day).valueOf() < new Date().valueOf() ? CHETWODEBLUE : //past
                    (isAccountGoingToBeClosed(period, user) ? BITTERSWEET : "#EBEBEB") //future
                  }
                >
                  {/* {new Date(day).getDate()}<br/> */}
                  {isSameDate(day, today, "d") ? 
                    <TodayTag>TODAY</TodayTag> 
                  : null}
                  {/* {period.recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length > 0 ?
                    <RechargeInfo>
                      <span className="material-symbols-outlined">nearby</span>
                      <RechargeInfoCounter>{period.recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length}</RechargeInfoCounter>
                    </RechargeInfo>
                  : null} */}
                </TimelineDay>
              )}

            </TimelineGrid>
          </Center>
          <Right>
            <TimeLabel>Period end</TimeLabel>
            <TimeValue>{getFormattedDate(end)}</TimeValue>
          </Right>
        </Wrapper>
        
        <hr/>
        <Wrapper>
          <Left>
            <PlanInfo>
              <PlanLabel>{new Date(period.endDate) > new Date() ? 
                "Current plan"
              :
                "Period plan"
              }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
            </PlanInfo>
          </Left>
          <Center>
            {plansPage && user && (!user.nextPlan || user.nextPlan.uuid !== user.currentPlan.uuid) && new Date(period.endDate) > new Date() ?
              <ChangePlanNotification>All plan changes will take effect from the NEXT RENEWAL</ChangePlanNotification>
            : null }
          </Center>
          <Right>
            {user ? 
              <PlanInfo>
                {user.status === "TO_BE_CLOSED" && showCta ?
                   <Link to="/account/profile"><OzButton size="small" variant={"silvertree-o"}>Reopen account</OzButton></Link>
                : user.status === "OPERATIVE" && !user.nextPlan && showCta ?
                  <Link to="/account/plans"><OzButton size="small" variant={"silvertree-o"}>Set next plan</OzButton></Link>
                : user.nextPlan && (user.nextPlan.uuid !== user.currentPlan.uuid) ?
                  <>
                    <NextPlanLabel>Next plan: </NextPlanLabel> <PlanTag to="/account/plans">{user.nextPlan.name}</PlanTag>
                  </>
                : 
                  null
                }
              </PlanInfo>
            : null}
          </Right>
        </Wrapper>
      </>);
    }else{
      let start = new Date(period.startDate);
      let end = new Date(period.endDate);
      let today = new Date(Date.now());

      return (<>
        <Wrapper>
          <Left>
            <TimeLabel>Period start</TimeLabel>
            <TimeValue>{getFormattedDate(start)}</TimeValue>
          </Left>
          <Center>
            <TimelineGrid size={getArrayFromInterval(start, end).length} space={3}>
              {getArrayFromInterval(start, end).map(day => 
                <TimelineDay
                  key={day}
                  color={
                    isSameDate(day, today, "d") ? SILVERTREE : //today
                    new Date(day).valueOf() < new Date().valueOf() ? CHETWODEBLUE : //past
                    (user && isAccountGoingToBeClosed(period, user) ? BITTERSWEET : "#EBEBEB") //future
                  }
                >
                  {/* {new Date(day).getDate()}<br/> */}
                  {isSameDate(day, today, "d") ? 
                    <TodayTag>TODAY</TodayTag> 
                  : null}
                  {period.recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length > 0 ?
                    <RechargeInfo>
                      <span className="material-symbols-outlined">nearby</span>
                      <RechargeInfoCounter>{period.recharges.filter(recharge => isSameDate(day, recharge.createDatetime, "d")).length}</RechargeInfoCounter>
                    </RechargeInfo>
                  : null}
                </TimelineDay>
              )}

            </TimelineGrid>
          </Center>
          <Right>
            <TimeLabel>Period end</TimeLabel>
            <TimeValue>{getFormattedDate(end)}</TimeValue>
          </Right>
        </Wrapper>
        {checkPermission("subPlan", "list") ? 
          <>
            <hr/>
            <Wrapper>
              <Left>
                <PlanInfo>
                  <PlanLabel>{new Date(period.endDate) > new Date() ? 
                    "Current plan"
                  :
                    "Period plan"
                  }:</PlanLabel><PlanTag to="/account/plans">{period.currentPlan.name}</PlanTag>
                </PlanInfo>
              </Left>
              <Center>
                {plansPage && user && (!user.nextPlan || user.nextPlan.uuid !== user.currentPlan.uuid) && new Date(period.endDate) > new Date() ?
                  <ChangePlanNotification>All plan changes will take effect from the NEXT RENEWAL</ChangePlanNotification>
                : null }
              </Center>
              <Right>
                {user ? 
                  <PlanInfo>
                    {user.status === "TO_BE_CLOSED" && showCta ?
                      <Link to="/account/profile"><OzButton size="small" variant={"silvertree-o"}>Reopen account</OzButton></Link>
                    : user.status === "OPERATIVE" && !user.nextPlan && showCta ?
                      <Link to="/account/plans"><OzButton size="small" variant={"silvertree-o"}>Set next plan</OzButton></Link>
                    : user.nextPlan && (user.nextPlan.uuid !== user.currentPlan.uuid) ?
                      <>
                        <NextPlanLabel>Next plan: </NextPlanLabel> <PlanTag to="/account/plans">{user.nextPlan.name}</PlanTag>
                      </>
                    : 
                      null
                    }
                  </PlanInfo>
                : null}
              </Right>
            </Wrapper>
          </>
        : null}
      </>);
    }
    
  }else{
    return (<></>);
  }
}

export default ReferencePeriodTimeline;
