import React, {useContext} from 'react';
import styled from 'styled-components';

import {OverlayContext} from './../contexts/OverlayContext';

import {OzBreadcrumbs, OzContainer, OzButton, OzCard} from '@ozwol/webui';

const Wrapper = styled.div`
`;

const ButtonWithAlert = ({
	title,
	text,
	onConfirm = () => {}, 
	onCancel = () => {}, 
	children = null
}) => {
  const {setOverlay} = useContext(OverlayContext);

  return (
		<>
			<Wrapper onClick={() => setOverlay(
				<OzContainer size="small">
					<OzCard
						footerLeft={<OzButton onClick={() => {setOverlay(null); onCancel();}} variant={"chetwodeblue-o"}>Back</OzButton>}
						footerRight={<OzButton onClick={() => {setOverlay(null); onConfirm();}} variant={"bittersweet"}>Confirm</OzButton>}
					>
						<OzBreadcrumbs values={[
							{
								name: title
							}
						]} />
						<br/>
						{text}								
					</OzCard>
				</OzContainer>
			)}>
				{children}
			</Wrapper>
		</>
  )
}

export default ButtonWithAlert;
