import React, {useState, useCallback, useEffect, useContext} from 'react';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from './../../../contexts/OverlayContext';

import {LILAC, OzLoading} from "@ozwol/webui";

import DownloadService from '../../../services/DownloadService';

const Wrapper = styled.div`
`;
const Loader = styled.div`
  float: right;
`;


const DownloadPolling = ({uuid}) => {
  const {setPopup} = useContext(OverlayContext);
  

  const [text, setText] = useState(<><Loader><OzLoading bgcolor={LILAC} size="20px" /></Loader>Dowloading...</>);


	const check = useCallback((uuid) => {
    DownloadService.get(uuid).then((response) => {
      if(response.status === "SUCCESS"){
				const downloadLink = document.createElement('a');
        downloadLink.href = response.downloadUrl;
        // downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

				setPopup(null);
			}else if(response.status === "ERROR"){
				setText(response.errorDescription)
			}else{
				setText(<><Loader><OzLoading bgcolor={LILAC} size="20px" /></Loader>Preparing your files...</>)
				setTimeout(() =>{
					check(uuid);
				}, 2000);
			}
    }).catch((error) => {
      // setFetchError(formatError(error));
    });
  }, [setPopup]);
  

  useEffect(() => {
		check(uuid);
  }, [uuid, check]);

  return (
    <Wrapper>
      {text}
    </Wrapper>
  )
}

export default DownloadPolling;
