import ApiCore from './ApiCore';


class VersionService extends ApiCore {

  getVersion() {
    return new Promise((resolve, reject) => {
      this.instance.get('public/version').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

}

let service =  new VersionService();
export default service;
