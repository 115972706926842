import React, {useContext, useState, useCallback} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../contexts/OverlayContext';

import {OzInput, OzButton, OzCard} from '@ozwol/webui';

import CognitoService from '../services/CognitoService';

import MfaWizard from './../pages/account/common/MfaWizard';

import CardHeaderTitle from '../components/CardHeaderTitle';
import ErrorMessage from '../components/ErrorMessage';
import Success from '../components/Success';

import {formatError} from '../helpers';

const Container = styled.div`
  width: 450px;
`;

const ModalMFA3 = ({user, onSuccess}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [otpCode, setOtpCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const verifyMFA = useCallback(() => {
    setErrorMessage(null);
    CognitoService.verifyMFA(otpCode).then((response) => {
      
      CognitoService.enableMFA().then((response2) => {
        setOverlay(<Success>Your data has been successfully saved!</Success>);
        setTimeout(() => {
          onSuccess();
          setOverlay(null);
        }, 2000);
      }).catch((error) => {
        setErrorMessage(formatError(error));
      });
    
    }).catch((error) => {
      setErrorMessage("Invalid code");
    });
  }, [onSuccess, otpCode, setOverlay]); /* item, setOverlay */

  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle title={"Enter the Code"} />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Cancel</OzButton>}
        footerRight={<OzButton variant={"chetwodeblue-o"} onClick={() => verifyMFA()}>Verify</OzButton>}
      >
        Enter the code displayed in the app below. Once enabled, you can use the app to generate new codes for future logins.
        <br/>
          
        <OzInput
          label="OTP Code"
          width="100%"
          value={otpCode ? otpCode : ""}
          onChange={(val) => {
            setOtpCode(val);
          }}
          required
        />

        <br/>
        <MfaWizard step={3} />

        <ErrorMessage noMargin>
          {errorMessage}
        </ErrorMessage>


      </OzCard>

    </Container>
  );

}

export default ModalMFA3;
