import React  from 'react';
import {Helmet} from 'react-helmet';
import {CDN} from './../config';

const Meta = ({title}) => {
  return (
    <Helmet>
      <title>{title} | Ozwol Console</title>
      <link
        rel="icon"
        type="image/png"
        href={CDN+"favicon/console-"+process.env.REACT_APP_ENV+".svg"}
        sizes="16x16"
      />
    </Helmet>
  )
}

export default Meta;
