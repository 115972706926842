import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {OzTable, OzLoading, OzContainer, OzRow, OzCol, OzCard} from '@ozwol/webui';

import AccountService from '../../services/AccountService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import NoResult from '../../components/NoResult';


import {checkPermission, formatError, getFormattedDate, convertNumber, getUnixDate} from '../../helpers';


const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(3),
  & table tr td:nth-child(4){
    word-break: break-all;
    white-space: unset;
  }
`;

const PeriodHistory = ({refScrollerPage}) => {
  const [list, setList] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const getList = useCallback(() => {
    if(checkPermission("period", "list")){
      AccountService.listPeriods().then((response) => {
        if(response && response.result){
          setList(response.result);
        }
      }).catch((error) => {
        setList(null);
        setFetchError(formatError(error));
      });
    }
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  

  return (
    <>
      <Meta title={"Period history"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="history"
          breadcrumbsText={["Period history", "Account"]}
          refScrollerPage={refScrollerPage}
        />

        <OzRow>
          <OzCol>
            <OzCard
              headerLeft={
                <CardHeaderTitle title={"Period history"} />
              }
              headerSeparator={true}
            >
              <OzRow>
                <OzCol widthmd="12">
                  {fetchError ?
                    <ErrorMessage>{fetchError}</ErrorMessage>
                  : list ?
                    list.length > 0 ?
                      <>
                        <TableWrapper>
                          <OzTable
                            columns={[
                              "#",
                              "Period",
                              "Plan",
                              "Included credits",
                              "Credits consumed",
                              "Recharges",
                              "Asset processed",
                              "Period cost*"
                            ]}
                            values={
                              list.map((item, i) => ({
                                style: {
                                  color: item.level === "ERROR" ? "#FF4848" : null
                                },
                                values: [
                                  item.sequenceNumber,
                                  getUnixDate(item.startDate) < new Date() && (new Date() < getUnixDate(item.endDate) || item.endDate === null) ? "Current period" : getFormattedDate(item.startDate) + " - " + getFormattedDate(item.endDate),
                                  item.currentPlan.name + " (" + convertNumber(item.currentPlan.price,0)+"€)",
                                  convertNumber(item.currentPlan.credits,0),
                                  convertNumber(item.creditsConsumed,0),
                                  convertNumber(item.rechargesCount,0) + " (" + convertNumber(item.currentPlan.rechargePrice,0)+"€)",
                                  convertNumber(item.assetsProcessed,0),
                                  convertNumber(item.currentPlan.price+(item.rechargesCount*item.currentPlan.rechargePrice),0)+"€"
                                ]
                              }))
                            }
                          />
                        </TableWrapper>

                        <br/>
                        <br/>
                        *The "Period Cost" may not coincide with the billing. Plan is billed in advance while the recharges at the end of the relevant period.

                      </>
                    : <NoResult />
                  : <OzLoading />}

                </OzCol>
              </OzRow>
            </OzCard>
          </OzCol>
        </OzRow>

      </OzContainer>
    </>
  );
}

export default PeriodHistory;
