//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../contexts/OverlayContext';

import {OzContainer, OzButton, OzTextarea, OzCol, OzRow, OzInput, OzCard} from "@ozwol/webui";

import HotfoldersService from '../services/HotfoldersService';

import CardHeaderTitle from '../components/CardHeaderTitle';
import ErrorMessage from '../components/ErrorMessage';
import HealthyWrapper from '../components/HealthyWrapper';

import {formatError} from '../helpers';


const ModalParseRegex = ({pathNameRegexp, fileNameRegexp, destination, onSuccess}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    path_name_regexp: pathNameRegexp,
    file_name_regexp: fileNameRegexp,
    destination: destination,
    sources: []
  });
  
  const [errorPathNameRegex, setErrorPathNameRegex] = useState(null);
  const [errorFileNameRegex, setErrorFileNameRegex] = useState(null);

  const [result, setResult] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const save = () => {
    onSuccess(item);
    setOverlay(null);
  };

  useEffect(() => {
      if(item.path_name_regexp !== pathNameRegexp){
        HotfoldersService.checkRegex({
          "regex": item.path_name_regexp,
          "text": ""
        }).then((response) => {
          setErrorPathNameRegex(null);
        }).catch((error) => {
          setErrorPathNameRegex(formatError(error));
        });    
      }else{
        setErrorPathNameRegex(null);
      }
      
      if(item.file_name_regexp !== fileNameRegexp){
        HotfoldersService.checkRegex({
          "regex": item.file_name_regexp,
          "text": ""
        }).then((response) => {
          setErrorFileNameRegex(null);
        }).catch((error) => {
          setErrorFileNameRegex(formatError(error));
        });  
      }else{
        setErrorFileNameRegex(null);
      }
      
  }, [item, pathNameRegexp, fileNameRegexp]);

  useEffect(() => {
    HotfoldersService.parseRegex(item).then((response) => {
      setErrorMessage(null);
      setDisabled(false);
      setResult(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
      setDisabled(true);
      setResult("");
    });    
  }, [item]);

  return (
    <OzContainer>
      
      <HealthyWrapper>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Parse RegExp"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Apply</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="6">
              <OzInput
                label="Path Name RegExp"
                width="100%"
                value={item && item.path_name_regexp ? item.path_name_regexp : ""}
                highlighted={item && item.path_name_regexp && item.path_name_regexp !== pathNameRegexp}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.path_name_regexp = val;
                  setItem(tempItem);
                }}
              />
              {errorPathNameRegex ?
                <ErrorMessage>
                  {errorPathNameRegex}
                </ErrorMessage>
              : null}
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="File Name RegExp"
                width="100%"
                value={item && item.file_name_regexp ? item.file_name_regexp : ""}
                highlighted={item && item.file_name_regexp && item.file_name_regexp !== fileNameRegexp}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.file_name_regexp = val;
                  setItem(tempItem);
                }}
              />
              {errorFileNameRegex ?
                <ErrorMessage>
                  {errorFileNameRegex}
                </ErrorMessage>
              : null}
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Destination"
                width="100%"
                value={item && item.destination ? item.destination : ""}
                highlighted={item && item.destination && item.destination !== destination}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.destination = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzTextarea
                label="Text"
                width="100%"
                value={item && item.sources ? item.sources.join("\n") : ""}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.sources = val.split("\n");
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzTextarea
                label="Result"
                width="100%"
                value={result && result.length > 0 ? result.map(item => item.destination).join("\n") : ""}
                disabled
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzTextarea
                label="Matches"
                width="100%"
                value={result && result.length > 0 ? result.map(item => JSON.stringify(item.matches)).join("\n") : ""}
                disabled
              />
            </OzCol>
            
          </OzRow>
          
          {errorMessage && !errorPathNameRegex && !errorFileNameRegex ?
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
          : null}
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default ModalParseRegex;
