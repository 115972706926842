import React, {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';

import {CHETWODEBLUE, OzLoading, OzTypo, OzContainer} from "@ozwol/webui";

import JoinService from './../services/JoinService';

import Meta from './../components/Meta';

import {formatError} from './../helpers';
import {CDN} from './../config';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #F6F7FB;
`;

const Content = styled.div`
  text-align: center;
  width: 100%;
`;

const Join = () => {
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  // const [error, setError] = useState(null);
  // let navigate = useNavigate();
  const [queryParams] = useSearchParams();

    useEffect(() => {
    if(queryParams && queryParams.get("c")){
      JoinService.join({"c": queryParams.get("c")}).then((response) => {
        setSuccess(true);
        setMessage(response.given_name);
      }).catch((error) => {
        setSuccess(false);
        setMessage(formatError(error));
      });
    }
  }, [queryParams]);


  
  return (
    <Wrapper>
      <Meta title={"Join"} />
      <Content>        
        {success === null ?
          <OzLoading />
        : success === true ?
          <OzContainer size="small">
            <OzTypo variant="h1c" color={CHETWODEBLUE}>Hi {message}, welcome!</OzTypo>
            <span style={{"color": CHETWODEBLUE}}>We're glad you're here.</span>
            <br/>
            <img width="100%" src={CDN + "console-assets/landing-coworker-invitation-success.png"} alt="success" />
            <OzTypo variant={"h2c"}>Shortly you will receive an email with instructions to access Ozwol Console.</OzTypo>
          </OzContainer>
        : success === false ?
          <OzContainer size="small">
            <OzTypo variant="h1c" color={CHETWODEBLUE}>{message}</OzTypo>
            <br/>
            <OzTypo variant={"breadcrumb-small"}>If you suspect an issue, please contact the company responsible for sending the invitation.</OzTypo>
          </OzContainer>
        : null }
      </Content>
    </Wrapper>
  )
  
}

export default Join;
