import React, {useState} from "react";
import styled from 'styled-components';
import CognitoService from "../../services/CognitoService";


import FormRegistration from "./forms/FormRegistration";
import FormEmailValidation from "./forms/FormEmailValidation";
import FormLogin from "./forms/FormLogin";
import FormMfa from "./forms/FormMfa";
import FormNewPassword from "./forms/FormNewPassword";
import FormNewPasswordNoCode from "./forms/FormNewPasswordNoCode";
import FormForgotPassword from "./forms/FormForgotPassword";
// import FormRecoverPassword from "./forms/FormRecoverPassword";
import FormSetName from "./forms/FormSetName";
import FormSuccess from "./forms/FormSuccess";


import {OzLogo, OzContainer} from "@ozwol/webui";

const Wrapper = styled.div`
`;


const Authenticator = ({
  entryPoint = "login",
  enableRegistration=true,
  requiredGroup=null,
  clientId,
  // region,
  onSuccess
}) => {
  // const [CognitoService] = useState( new CognitoService({
  //   userPoolId: userPoolId,
  //   clientId: clientId,
  //   region: userPoolId.split("_")[0],
  //   identityPoolId: identityPoolId
  // }));
  const [page, setPage] = useState(entryPoint);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [sessionUser, setSessionUser] = useState(null);

  const responseHandler = (response) => {
    switch(response.action){
      case "registration":
        registration(response.params.email, response.params.password1, response.params.password2, response.params.givenName, response.params.familyName, response.params.locale);
        break;
      case "email-validation":
        setEmailValidation(response.params.email, response.params.code);
        break;
      case "login":
        login(response.params.email, response.params.password);
        break;
      case "mfa":
        mfa(response.params.email, response.params.otpCode);
        break;
      case "new-password":
        setNewPassword(response.params.password1, response.params.password2, response.params.code);
        break;
      case "new-password-nocode":
        setNewPasswordNoCode(response.params.password1, response.params.password2);
        break;
      case "forgot-password":
        forgotPassword(response.params.email);
        break;
      // case "recover-password":
      //   recoverPassword(response.params.code, response.params.password1, response.params.password2);
      //   break;
      case "navigate":
        setPage(response.params.page);
        break;
      default:
        break;
    }
  };

  const registration = (email, password, givenName, familyName, locale) => {
    setError(null);
    CognitoService.registration(clientId, email, password, givenName, familyName, locale).then(response => {
      if(!response.UserConfirmed){
        setSessionUser({
          email: email,
          givenName: givenName,
          familyName: familyName
        });
        setPage("email-validation");
      }else{
        setPage("login");
      }
    }).catch(error => {
      console.log(error);
      setError(error.message);
    });
  };


  const login = (email, password) => {
    setError(null);
    CognitoService.login(email, password).then(response => {
      if(!requiredGroup || (response.ParsedJwtToken["cognito:groups"] && response.ParsedJwtToken["cognito:groups"].includes(requiredGroup))){
        setPage("success");
        onSuccess(response);
      }else{
        setError("Non hai i permessi.");
      }
    }).catch(error => {
      switch (error.ChallengeName) {
        case "SOFTWARE_TOKEN_MFA":
          setEmail(email);
          setSessionUser(error.Session);
          setPage("mfa");
          break;
        case "NEW_PASSWORD_REQUIRED":
          setEmail(email);
          setSessionUser(error.Session);
          setPage("new-password-nocode");
          break;
        default:
          setError(error.message);
          break;
      }
    });
  };


  const mfa = (email, otpCode) => {
    setError(null);
    CognitoService.checkMfa(email, otpCode, sessionUser).then(response => {
      setPage("success");
      console.log(response);
      onSuccess(response);
    }).catch(response => {
      console.log(response);
      setError(response.message);
    });
  };

  const setEmailValidation = (email, code) => {
    setError(null);
    CognitoService.emailValidation(clientId, email, code).then(response => {
      setPage("login");
    }).catch(response => {
      console.log(response);
      setError(response.message);
    });
  };

  const forgotPassword = (email) => {
    setError(null);
    CognitoService.forgotPassword(email).then(response => {
      setSessionUser(email);
      setPage("new-password");
    }).catch(response => {
      console.log(response);
      setError(response.message);
    });
  };

  const setNewPassword = (password1, password2, code) => {
    setError(null);
    CognitoService.setNewPassword(sessionUser, password1, code).then(response => {
      setPage("login"); //success
      // onSuccess(response.data); // ???
    }).catch(response => {
      console.log(response);
      setError(response.message);
    });
  };

  const setNewPasswordNoCode = (password1, password2) => {
    setError(null);
    CognitoService.newPasswordChallenge(email, password1, sessionUser).then(response => {
      if(!requiredGroup || (response.ParsedJwtToken["cognito:groups"] && response.ParsedJwtToken["cognito:groups"].includes(requiredGroup))){
        setPage("success");
        onSuccess(response);
      }else{
        setError("Non hai i permessi.");
      }
    }).catch(response => {
      console.log(response);
      setError(response.message);
    });
  };

  // const recoverPassword = (code, password1) => {
  //   setError(null);
  //   CognitoService.recoverPassword(sessionUser, code, password1).then(response => {
  //     if(response.success){
  //       setPage("success");
  //     }else{
  //       setError(response.message);
  //     }
  //   }).catch(response => {
  //     console.log(response);
  //     setError(response.message);
  //   });
  // };

  return (
    <Wrapper>
      <OzContainer size="small">
        <OzLogo height="20px"/>
        <br/><br/>
        {page === "registration" && enableRegistration ?
          <FormRegistration
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "email-validation" && sessionUser ?
          <FormEmailValidation
            sessionUser={sessionUser}
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "login" ?
          <FormLogin
            enableRegistration={enableRegistration}
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "mfa" ?
          <FormMfa
            email={email}
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "set-name" && sessionUser ?
          <FormSetName
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "forgot-password" ?
          <FormForgotPassword
            onConfirm={(response) => responseHandler(response)}
            error={error}
          />
        : page === "new-password" && sessionUser ?
          <FormNewPassword
            sessionUser={sessionUser}
            onConfirm={(response) => responseHandler(response)}
            onError={(response) => setError(response)}
            error={error}
          />
        : page === "new-password-nocode" && sessionUser ?
          <FormNewPasswordNoCode
            sessionUser={sessionUser}
            onConfirm={(response) => responseHandler(response)}
            onError={(response) => setError(response)}
            error={error}
          />
        // : page === "recover-password" ?
        //   <FormRecoverPassword
        //     sessionUser={sessionUser}
        //     onConfirm={(response) => responseHandler(response)}
        //     error={error}
        //   />
        : page === "success" ?
          <FormSuccess
            onConfirm={(response) => responseHandler(response)}
          />
        : <>error</> }
      </OzContainer>
    </Wrapper>
  )
}

export default Authenticator;
