import React, {useContext, useEffect} from 'react';
import {useNavigate, useParams, Navigate} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import CognitoService from './../../services/CognitoService';
import AccountService from './../../services/AccountService';
import Authenticator from './../../components/Authenticator/Authenticator';


import {FORCE_LOGOUT} from './../../config';

import Meta from './../../components/Meta';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #F6F7FB;
`;

const Content = styled.div`
  text-align: center;
  width: 100%;
`;

const Auth = () => {
  // const [sessionUser, setSessionUser] = useState(null);
  // const [error, setError] = useState(null);
  const {setCognito} = useContext(CognitoContext);
  let navigate = useNavigate();
  const {page} = useParams();

  useEffect(() => {
    if(page === "login" || page === "registration" || page === "logout"){
      CognitoService.logout();
      setCognito(null);
    }
  }, [page, setCognito]);


  if(page === "logout"){
    return (
      <Navigate to="/auth/login" />
    )
  }else{
    return (
      <Wrapper>
        <Meta title={"Sign in"} />
        <Content>
          <Authenticator
            userPoolId={process.env.REACT_APP_COGNITO_POOL_ID}
            clientId={process.env.REACT_APP_COGNITO_CLIENT_ID}
            // region={process.env.REACT_APP_COGNITO_REGION}
            onSuccess={(response)=> {  
              localStorage.removeItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
              
              response.forceLogout = FORCE_LOGOUT;
              response.ownerUuid = response.ParsedJwtToken.sub;
              response.groups = response.ParsedJwtToken && response.ParsedJwtToken["cognito:groups"] && response.ParsedJwtToken["cognito:groups"].length > 0 ? response.ParsedJwtToken["cognito:groups"] : [];

              localStorage.setItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(response));
              setCognito(response);
              
              AccountService.getPermissions().then(response2 => {
                response.permissions = response2;                   
                localStorage.setItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(response));
                setCognito(response);                

                if(response.groups.includes("OzwolCoworker")){
                  AccountService.getCoworker(response.ParsedJwtToken.sub).then(response3 => {
                    response.ownerUuid = response3.ownerUuid;        
                    localStorage.setItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(response));
                    setCognito(response); 
                    navigate("/");
                  })
                }else{
                  navigate("/");
                }
              })
              
            }}
            entryPoint={page}
            enableRegistration={false}
          />
        </Content>
      </Wrapper>
    )
  }
}

export default Auth;
