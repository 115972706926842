//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzSelect, OzUserTag, OzColorpicker, OzCol, OzRow, OzInput, OzCard} from "@ozwol/webui";

import ProjectsService from '../../services/ProjectsService';
import UsersService from '../../services/UsersService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, testValidField} from '../../helpers';



const InsiderProjectsModalNew = () => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [ownerUuid, setOwnerUuid] = useState();

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const [searchUsers, setSearchUsers] = useState("");
  const [listUsers, setListUsers] = useState([]);


  const save = () => {
    ProjectsService.adminCreate(item, ownerUuid).then((response) => {
      window.location.replace("/insider/projects/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  };

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(ownerUuid, true)){
        valid = false;
      }
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.color, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, ownerUuid]);

  useEffect(() => {
    UsersService.adminList({
      "search": searchUsers.length > 2 ? searchUsers : "",
    }).then((response) => {
      setListUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchUsers]);

  return (
    <OzContainer>
      
      <HealthyWrapper error={fetchError}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Add project"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="12">
              <OzSelect
                label="Owner"
                width="100%"
                placeholder="Owner"
                selected={ownerUuid ? ownerUuid : null}
                highlighted={ownerUuid}
                values={listUsers.map((item) => ({
                  "name": <OzUserTag
                      name={item.givenName + " " + item.familyName}
                      company={item.company}
                      direction={"horizontal"}
                    />,
                  "value": item.uuid,
                }))}
                onChange={(val) => {
                  setOwnerUuid(val);               
                }}
                maxHeight={"200px"}
                searchable={true}
                search={searchUsers}
                onSearch={(val) => setSearchUsers(val)}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Name"
                width="100%"
                value={item && item.name ? item.name : ""}
                highlighted={item && item.name && item.name.length > 0}
                placeholder="Name"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.name = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzColorpicker
                label="Color"
                width="100%"
                value={item && item.color ? item.color : ""}
                highlighted={item && item.color && item.color.length > 0}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.color = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderProjectsModalNew;
