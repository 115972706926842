import { 
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  ChangePasswordCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand, 
  GetUserCommand,
  AssociateSoftwareTokenCommand,
  VerifySoftwareTokenCommand,
  SetUserMFAPreferenceCommand
} from "@aws-sdk/client-cognito-identity-provider";

class CognitoService {

  constructor() { 
    this.client = new CognitoIdentityProviderClient({
      region: process.env.REACT_APP_COGNITO_POOL_ID.split("_")[0]
    });
  }


  login(username, password) {
    return new Promise((resolve, reject) => {
      const cmd = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
            "USERNAME": username,
            "PASSWORD": password
        },
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
      })      
      this.client.send(cmd).then(response => {
        if(response.AuthenticationResult){
          resolve({
            ...response.AuthenticationResult,
            "ParsedJwtToken": this.parseJwt(response.AuthenticationResult.IdToken)
          });
        }else{
          reject(response);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }


  checkPassword(password) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
            "USERNAME": user.ParsedJwtToken.email,
            "PASSWORD": password
        },
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
      })      
      this.client.send(cmd).then(response => {
        if(response.AuthenticationResult){
          resolve(true);
        }else{
          resolve(false);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  newPasswordChallenge(username, password, session) {
    return new Promise((resolve, reject) => {
      // let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new RespondToAuthChallengeCommand({
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        ChallengeName: "NEW_PASSWORD_REQUIRED",
        ChallengeResponses: {
          "USERNAME": username,
          "NEW_PASSWORD": password
        },
        Session: session
    })      
      this.client.send(cmd).then(response => {
        if(response.AuthenticationResult){
          resolve({
            ...response.AuthenticationResult,
            "ParsedJwtToken": this.parseJwt(response.AuthenticationResult.IdToken)
          });
        }else{
          reject(response);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new ChangePasswordCommand({
        PreviousPassword: oldPassword,
        ProposedPassword: newPassword,
        AccessToken: user.AccessToken
    })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  forgotPassword(username) {
    return new Promise((resolve, reject) => {
      // let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new ForgotPasswordCommand({
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        Username: username
    })      
      this.client.send(cmd).then(response => {
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  }

  setNewPassword(username, newPassword, code) {
    return new Promise((resolve, reject) => {
      // let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new ConfirmForgotPasswordCommand({
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        Username: username,
        Password: newPassword,
        ConfirmationCode: code
    })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }


  getUser() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new GetUserCommand({
        AccessToken: user.AccessToken
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  setMFA() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new AssociateSoftwareTokenCommand({
        AccessToken: user.AccessToken,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  verifyMFA(code) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new  VerifySoftwareTokenCommand({
        AccessToken: user.AccessToken,
        UserCode: code
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  checkMfa(username, code, session) {
    return new Promise((resolve, reject) => {
      const cmd = new RespondToAuthChallengeCommand({
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        ChallengeName: "SOFTWARE_TOKEN_MFA",
        ChallengeResponses: {
          "USERNAME": username,
          "SOFTWARE_TOKEN_MFA_CODE": code
        },
        Session: session
      })      
      this.client.send(cmd).then(response => {
        if(response.AuthenticationResult){
          resolve({
            ...response.AuthenticationResult,
            "ParsedJwtToken": this.parseJwt(response.AuthenticationResult.IdToken)
          });
        }else{
          reject(response);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }


  enableMFA() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new SetUserMFAPreferenceCommand({
        AccessToken: user.AccessToken,
        SoftwareTokenMfaSettings: { 
          "Enabled": true,
          "PreferredMfa": true
        }
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  disableMFA() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
      const cmd = new SetUserMFAPreferenceCommand({
        AccessToken: user.AccessToken,
        SoftwareTokenMfaSettings: {
          "Enabled": false
        }
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }
  


  refresh() {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
    
      const cmd = new InitiateAuthCommand({
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
            "REFRESH_TOKEN": user.RefreshToken
        },
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
      })      
      this.client.send(cmd).then(response => {
        response["RefreshToken"] = user.RefreshToken; //valutare se spostarlo in CognitoService
        localStorage.setItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify({
          "AccessToken": response.data.AuthenticationResult.AccessToken,
          "IdToken": response.data.AuthenticationResult.IdToken,
          "ExpiresIn": response.data.AuthenticationResult.ExpiresIn,
          "RefreshToken": response.data.AuthenticationResult.RefreshToken,
          "ParsedJwtToken": this.parseJwt(response.data.AuthenticationResult.IdToken)
        }));  
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  }

  

  logout(){
    localStorage.setItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), null);
  }
  
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  
}

let service =  new CognitoService();
export default service;