import React from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, OzCard} from '@ozwol/webui';

const Message = styled.div`
  border-radius: 9px;
  background: ${CHETWODEBLUE}13;
  padding: 30px;
  text-align: center;
  color: ${CHETWODEBLUE};
  font-weight: bold;
  font-size: 16px;
`;

const Success = ({children}) => {  
  
  return <>
    <OzCard>
      <Message>{children}</Message>
      </OzCard>
    </>;
}

export default Success;
