import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;

const FormMfa = ({email, onConfirm, onError, error}) => {
  const [otpCode, setOtpCode] = useState('');


  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Multi-Factor Authentication</OzTypo>
      <>Please enter the authentication code.</>

      <OzRow>
        <OzCol widthmd="12">
          <OzInput
            label={"OTP"}
            name="otp"
            width="100%"
            value={otpCode}
            onChange={(val) => setOtpCode(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => 
            onConfirm({
              action: "mfa",
              params: {
                email: email,
                otpCode: otpCode
              }
            })
          }>Confirm</OzButton>
        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormMfa;
