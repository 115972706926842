import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class DownloadService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/downloads'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      if(uuid){
        this.instance.get('backoffice/downloads/'+uuid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      }else{
        reject();
      }
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/downloads', {
        bucket: process.env.REACT_APP_DEFAULT_BUCKET_NAME,
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  delete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/download/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new DownloadService();
export default service;