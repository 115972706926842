import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;

const FormSetName = ({onConfirm, error}) => {
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');


  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Set name</OzTypo>
      <>Enter your names.</>

      <OzRow>
        <OzCol widthmd="6">
          <OzInput
            label={"Given Name"}
            name="givenName"
            type="text"
            width="100%"
            value={givenName}
            onChange={(val) => setGivenName(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="6">
          <OzInput
            label={"Family Name"}
            name="familyName"
            type="text"
            width="100%"
            value={familyName}
            onChange={(val) => setFamilyName(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => onConfirm({
            action: "set-name",
            params: {
              givenName: givenName,
              familyName: familyName
            }
          })}>Save</OzButton>
        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormSetName;
