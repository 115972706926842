import React, {useState, useEffect, useCallback, useContext} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../../contexts/OverlayContext';
import { OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput, OzSelect} from '@ozwol/webui';

import AccountService from '../../services/AccountService';

import Success from '../../components/Success';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';

import {checkPermission, formatError, testValidField, clearObject, COUNTRIES} from '../../helpers';

const VatNote = styled.div`
  margin-top: 3px;
  font-size: 11px;
`;

const BillingInfo = ({refScrollerPage}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [editVat, setEditVat] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const getItem = useCallback(() => {
    AccountService.getUser().then((response) => {
      if(response){
        setItem(response);
        setOriginalItem(response);
        if(!response.vatNumber || response.vatNumber === ""){
          setEditVat(true);
        }
        
      }
    }).catch((error) => {
      setItem(null);
      setFetchError(formatError(error));
    });
  }, []);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    if(item.vatNumber){
      setEditVat(false);
    }
    let salvageableItem = {...item};
    if(salvageableItem.emailPec === ""){
      salvageableItem.emailPec = null;
    }
    AccountService.updateUser(clearObject(salvageableItem, ["company","address","city", "state", "zip", "countryId","vatNumber","sdiNumber","emailPec"])).then((response) => {
      setOverlay(<Success>Your data has been successfully saved!</Success>);
      setTimeout(() => {
        setOverlay(null);
      }, 2000);
      setOriginalItem(item);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, setOverlay]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.company, true)){
        valid = false;
      }
      if(!testValidField(item.address, true)){
        valid = false;
      }
      if(!testValidField(item.city, true)){
        valid = false;
      }
      if(!testValidField(item.zip, true)){
        valid = false;
      }
      if(!testValidField(item.state, true)){
        valid = false;
      }
      if(!testValidField(item.countryId, true)){
        valid = false;
      }
      if(!testValidField(item.vatNumber, true)){
        valid = false;
      }
      if(item.countryId === "IT" && !testValidField(item.sdiNumber, true) && !testValidField(item.emailPec, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem]);

  return (
    <>
      <Meta title={"Billing info"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="receipt_long"
          breadcrumbsText={["Billing info", "Account"]}
          refScrollerPage={refScrollerPage}
        />

        <HealthyWrapper error={fetchError} loading={!item}>
          {item ?
            <OzRow>
              <OzCol>
                <OzCard
                  headerLeft={
                    <CardHeaderTitle title={"Billing info"} />
                  }
                  headerSeparator={true}
                  footerRight={checkPermission("subBillingInfo", "update") ? <OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton> : null}
                >
                  <OzRow>
                    <OzCol widthmd="12">
                      <OzInput
                        label="Company"
                        width="100%"
                        value={item && item.company ? item.company : ""}
                        highlighted={item && originalItem && item.company !== originalItem.company}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.company = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>
                    <OzCol widthmd="12">
                      <OzInput
                        label="Address"
                        width="100%"
                        value={item && item.address ? item.address : ""}
                        highlighted={item && originalItem && item.address !== originalItem.address}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.address = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="City"
                        width="100%"
                        value={item && item.city ? item.city : ""}
                        highlighted={item && originalItem && item.city !== originalItem.city}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.city = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="ZIP"
                        width="100%"
                        value={item && item.zip ? item.zip : ""}
                        highlighted={item && originalItem && item.zip !== originalItem.zip}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.zip = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="State/Province"
                        width="100%"
                        value={item && item.state ? item.state : ""}
                        highlighted={item && originalItem && item.state !== originalItem.state}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.state = val ? val.slice(0,2) : "";
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzSelect
                        label="Country"
                        variant={"default"}
                        width={"100%"}
                        maxHeight={"300px"}
                        selected={item && item.countryId ? item.countryId.toUpperCase() : ""}
                        highlighted={item && originalItem && item.countryId !== originalItem.countryId}
                        placeholder={"Select project"}
                        values={COUNTRIES.map((item) => ({
                          "name": item.en,
                          "value": item.alpha2.toUpperCase()
                        }))}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.countryId = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!checkPermission("subBillingInfo", "update")}
                      />
                    </OzCol>

                    {item.countryId === "IT" ? 
                      <>                        
                        <OzCol widthmd="6">
                          <OzInput
                            label="SDI **"
                            width="100%"
                            value={item && item.sdiNumber ? item.sdiNumber : ""}
                            highlighted={item && originalItem && item.sdiNumber !== originalItem.sdiNumber}
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.sdiNumber = val;
                              setItem(tempItem);
                            }}
                            disabled={!checkPermission("subBillingInfo", "update")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="PEC **"
                            width="100%"
                            value={item && item.emailPec ? item.emailPec : ""}
                            highlighted={item && originalItem && item.emailPec !== originalItem.emailPec}
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.emailPec = val;
                              setItem(tempItem);
                            }}
                            disabled={!checkPermission("subBillingInfo", "update")}
                          />
                        </OzCol>
                      </>
                    : null}


                    <OzCol widthmd="12">
                      <OzInput
                        label="Vat Number"
                        width="100%"
                        value={item && item.vatNumber ? item.vatNumber : ""}
                        highlighted={item && originalItem && item.vatNumber !== originalItem.vatNumber}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.vatNumber = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!editVat || !checkPermission("subBillingInfo", "update")}
                      />
                      {!editVat ? <VatNote>If you need to change your vat number contact us</VatNote> : null}
                    </OzCol>
                    <OzCol widthmd="12">
                      ** SDI or PEC are required
                    </OzCol>
                  </OzRow>
                  <ErrorMessage>
                    {errorMessage}
                  </ErrorMessage>
                </OzCard>
              </OzCol>
            </OzRow>
          : null}
        </HealthyWrapper>

      </OzContainer>
    </>
  );
}

export default BillingInfo;
