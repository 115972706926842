import React from 'react';
import styled from 'styled-components';

import {OzCard} from "@ozwol/webui";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;

  display: flex;
  align-items: center;
`;
const Right = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
`;
const Icon = styled.div`
  margin-bottom: -5px;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
`;
const Block = styled.div`
  text-align: center;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid #707070;
`;
const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;
  margin-bottom: 2px;
`;
const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #303030;
`;
const BigValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #303030;
`;

const MonthlySales = ({ totPlans = 0, totRecharges=0 }) => {

  return (
    <OzCard>
      <Wrapper>
        <Left>
          <Icon><span class="material-symbols-outlined">price_change</span></Icon>&nbsp;
          <CardHeaderTitle>Monthly sales</CardHeaderTitle>
        </Left>
        <Right>
          <Block>
            <Label>Plans</Label>
            <Value>{totPlans} €</Value>
          </Block>
          <Block>
            <Label>Recharge amount</Label>
            <Value>{totRecharges} €</Value>
          </Block>
          <Block>
            <Label>Total</Label>
            <BigValue>{parseInt(totPlans) + parseInt(totRecharges)} €</BigValue>
          </Block>
        </Right>
      </Wrapper>
    </OzCard>
  );
}

export default MonthlySales;
