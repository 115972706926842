import React from 'react';
import styled from 'styled-components';

const Area = styled.div`
  position: relative;
  overflow: visible;
  top: -1px:
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);

  & > *{
    display: none;
  }
  &:hover > *{
    display: block;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: left;
  padding: 10px;
  background: #F6F7FB;
  border: 1px solid #D5D9ED;
  box-shadow: 0px 3px 2px #C1CCE838;
  white-space: nowrap;
  direction: ltr;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;
const Subtitle = styled.div`
  font-size: 11px;
`;
const Value = styled.div`
  color: #303030;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
`;

const MegaTooltip = ({title = null, subtitle = null, value = null, visible = false}) => {
	return (
    visible ? 
      <Wrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Value>{value}</Value>
      </Wrapper>
    :
      <Area>
        <Wrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Value>{value}</Value>
        </Wrapper>
      </Area>
    
	)
}

export default MegaTooltip;
