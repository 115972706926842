import React, {useContext} from 'react';
import {OverlayContext} from './../contexts/OverlayContext';

import {OzContainer, OzRow, OzCol, OzButton, OzCard} from '@ozwol/webui';

import CardHeaderTitle from './../components/CardHeaderTitle';


const PromptedButton = ({
	buttonText,
	buttonSize, 
	buttonVariant="bittersweet-o", 
	buttonNoMinWidth = false, 
	prompt,
	onConfirm, 
	disabled,
	children = null
}) => {
  const {setImportantOverlay} = useContext(OverlayContext);	
  return (
    <>
      <OzButton disabled={disabled} variant={buttonVariant} size={buttonSize} noMinWidth={buttonNoMinWidth} onClick={() => setImportantOverlay(
				<OzContainer size="small">
					<OzCard
            headerLeft={
              <CardHeaderTitle 
								title={
									prompt === "DELETE" ?
										<>
											Are you sure?
										</>
									: prompt === "TEST-DESTINATION" ?
										<>
											Warning
										</>
									:null
								}
							/>
            }
						footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setImportantOverlay(null)}>Back</OzButton>}
						footerRight={<OzButton onClick={() => {setImportantOverlay(null); onConfirm();}} variant={"bittersweet"}>Confirm</OzButton>}
					>
						<OzRow>
							<OzCol widthmd="12">
								{prompt === "DELETE" ?
									<>
										This operation cannot be undone.							
									</>
								: prompt === "TEST-DESTINATION" ?
								<>
									The Test will try to create, and subsequently delete, a file, named ozwol.test, on the configured destination. Please be sure that there are no active processes that could interfere with this operation or perform unexpected tasks.						
								</>
							:null}
							</OzCol>
						</OzRow>
					</OzCard>
				</OzContainer>
			)}>{buttonText}</OzButton>
    </>
  )
}

export default PromptedButton;
