import React, {useContext} from 'react';
// import axios from 'axios';
import styled from 'styled-components';
// import { FileUploader } from "react-drag-drop-files";
import {useParams} from 'react-router-dom';
import {OverlayContext} from './../../../contexts/OverlayContext';

// import {CognitoContext} from '../../contexts/CognitoContext';

import {BITTERSWEET, LILAC, BLACKPEARL, SPANISHGREY, CHETWODEBLUE, SILVERTREE, OzCheck, OzButton} from "@ozwol/webui";


import {convertSize, getFormattedDateTime} from '../../../helpers';
import ErrorPopup from '../../../components/ErrorPopup';

// import {formatError} from '../../../helpers';

const PREVIEWABLES_EXTS = ["jpg","jpeg","png","tif","tiff","bmp","gif","webp","avif"];
const PREVIEWABLE_MAX_SIZE = 100000000; //100MB

const TreeItem = styled.div`
  position: relative;
  // border: 1px solid lime;
  border-bottom: 1px dashed #dedede;
  display: flex;
  justify-content: space-between;
  cursor: ${props => props.onClick ? "pointer" : "default"};
 
  &:hover{
    background-color: ${LILAC};
  }

  background-color: ${props => props.isSelected ? LILAC : "transparent"};

`;
const TreeItemArrow = styled.div`
  width: 26px;
  height: 26px;
  margin-top: 1px;
  margin-right: -3px;
  cursor: ${props => props.onClick ? "pointer" : "default"};
  rotate: ${props => props.rotate === "true" ? 90 : 0}deg;
  transition: all 0.05s ease-in-out;
  flex-grow: 0;
  flex-shrink: 0;
`;

const TreeItemCheck = styled.div`
  margin-right: 5px;
  margin-left: 1px;
`;


const TreeItemIcon = styled.div`
  width: 26px;
  height: 26px;
  margin-right: -3px;
  // margin-right: 5px;
  // background: lime;
  cursor: ${props => props.onClick ? "pointer" : "default"};
  color: ${props => 
    props.isBroken ? BITTERSWEET : 
    props.isHotfolder ? SILVERTREE :  
    props.isFile ? BLACKPEARL : 
    props.isOutput ? 
    CHETWODEBLUE : SPANISHGREY};
  margin-bottom: -1px;
  flex-grow: 0;
  flex-shrink: 0;

  & span{
    // background: red;
    display: block;
  }

`;
const TreeItemRow = styled.div`
  color: ${props => props.isHotfolder || props.isFile ? BLACKPEARL : SPANISHGREY};
  // cursor: ${props => props.isFile ? "pointer" : "inherit"};
  padding: 0px 0px 0px ${props => props.level * 15}px;
  display: flex;
  align-items: center;
  width: 100%;

  
  
  & label{
    cursor: ${props => props.isHotfolder && props.isOutput ? "pointer" : "default"};
    pointer-events: ${props => props.isHotfolder && props.isOutput ? "auto" : "none"};
    width: 100%;
  }
  & label > div:nth-last-child(2){
    border: 2px dashed ${CHETWODEBLUE};
    background-color: ${CHETWODEBLUE}66;
    border-radius: 0;
    opacity: 1;
  }
  & label span{
    display: none;
  }

  
  
  &:hover *{
    opacity: 1;
  }
`;
const TreeItemName = styled.div`
  padding: 8px 15px 8px 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.isBroken ? BITTERSWEET : "inherit"};
`;
const TreeItemSize = styled.div`
   padding: 0px 10px;
   white-space: nowrap;
`;
const TreeItemDate = styled.div`
  padding: 0px 15px 0px 0px;
  white-space: nowrap;
  font-size: 11px;
  width: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  font-family: Consolas;
  text-align: right;
`;
const TreeItemButtons = styled.div`
  // border: 1px solid red;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
	flex-direction: row;
	align-items: center;
	gap: 5px;

  
  & span{
    font-size: 20px;
  }
`;
const RowButton = styled.div`
	// margin-top: 4px;

  opacity: 0;

	& > button > span{
		font-size: 20px;
		line-height: 24px;
	}
`;
// const TreeItemCounter = styled.div`
//   // color: #fff;
//   background-color: ${BORDER};
//   padding: 0px 6px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
// `;
// const TreeItemRemove = styled.div`
//   color: #fff;
//   background-color: ${BITTERSWEET};
//   padding: 0px 6px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   opacity: 0;
  
//   div:hover > &{
//     opacity: 1;
//   }
//   ${props => props.onClick ? `
//     cursor: pointer;
//   ` : `
//     opacity: 0 !important;
//   `};
// `;




const TreeFiles = ({
	item, 
	getFiles, 
  deleteFile,
  previewFile,
	filesPaths, 
	openPaths, 
	level = -1,
  selected,
  setSelected,
  showTemp,
  logsList
}) => {
  const {projectUuid} = useParams();
  const {setOverlay} = useContext(OverlayContext);


	const isOutput = (item) => {
		return item && item.fullPath.startsWith(projectUuid + "/HOTFOLDERS/OUTPUT")
	}
	const isBroken = (path, logsList) => {
    return logsList && 
      logsList.filter(
        (item) => 
          item && 
          item.document.source_file_key === "ozb://" + process.env.REACT_APP_DEFAULT_BUCKET_NAME + "/" + path.Key &&
          item.document.create_timestamp*1000 > path.LastModified.getTime()
      ).length > 0 && 
      logsList.filter(
        (item) => 
          item &&
          item.document.source_file_key === "ozb://" + process.env.REACT_APP_DEFAULT_BUCKET_NAME + "/" + path.Key &&
          item.document.create_timestamp*1000 > path.LastModified.getTime()
      )[0].document.result === "ERROR"
	}

  // console.log(status);

  return (
         <>
          
          { 
            filesPaths && item
            && filesPaths.filter((path) => true || item.fullPath === path.Key.slice(0, path.Key.lastIndexOf("/"))).length > 0
            && (openPaths.includes(item.fullPath)) ?
            <>
              {filesPaths.filter((path) => item.fullPath === path.Key.slice(0, path.Key.lastIndexOf("/"))).map((path, j) => (
                showTemp || path.Key.toLowerCase().slice(path.Key.lastIndexOf("/")+1).slice(0,5) !== "_temp" ? 
                  <TreeItem>
                    <TreeItemRow isFile={true} level={level+1} isOutput={isOutput(item)}>
                      <TreeItemArrow />
                      {isOutput(item) ?
                        <TreeItemCheck>
                          <OzCheck selected={selected.includes(path.Key)} onChange={() =>{
                            selected.includes(path.Key) ? 
                              setSelected(selected.filter((item) => item !== path.Key))
                            :
                              setSelected(selected.concat([path.Key]))
                          }} />
                        </TreeItemCheck>
                      : null }
                      <TreeItemIcon 
                        isBroken={isBroken(path, logsList)}
                        isFile={true}
                      ><span className="material-symbols-outlined">
                        {isBroken(path, logsList) ?
                          "broken_image"
                        :
                          "image"
                        }
                      </span></TreeItemIcon>
                      <TreeItemName
                        isBroken={isBroken(path, logsList)}
                      >
                        {path.Key.slice(path.Key.lastIndexOf("/")+1)}                      
                        
                      </TreeItemName>
                      <TreeItemSize>
                        {convertSize(path.Size)}
                      </TreeItemSize>
                      {path.LastModified ? 
                        <TreeItemDate>
                          {getFormattedDateTime(path.LastModified)}
                        </TreeItemDate>
                      : null }
                      <TreeItemButtons>
                        {isOutput(item) ? 
                          <>
                            <RowButton>
                              <OzButton 
                                variant="push-black" 
                                size="small" 
                                disabled={!PREVIEWABLES_EXTS.includes(path.Key.split('.').pop()) || path.Size > PREVIEWABLE_MAX_SIZE}
                                onClick={() => previewFile(path.Key)}
                              >
                                <span className="material-symbols-outlined">visibility</span> Preview
                              </OzButton>
                            </RowButton>
                            <RowButton>
                              <OzButton 
                                variant="push-black" 
                                size="small"
                                onClick={() => getFiles([path.Key])}
                              >
                                <span className="material-symbols-outlined">download</span> Download
                              </OzButton>
                            </RowButton>
                          </>
                        :
                        <>
                          {isBroken(path, logsList) ?
                            <RowButton>
                              <OzButton 
                                variant="push-black" 
                                size="small" 
                                onClick={() => setOverlay(
                                  <ErrorPopup>
                                    {logsList.filter((item) => item.document.source_file_key === "ozb://" + process.env.REACT_APP_DEFAULT_BUCKET_NAME + "/" + path.Key)[0].document.error_description}
                                  </ErrorPopup>
                                )}
                              >
                                <span className="material-symbols-outlined">broken_image</span> Show error
                              </OzButton>
                            </RowButton>
                          : <div style={{"width": "102px" }} /> }
                          <RowButton>
                            <OzButton 
                              variant="push-bittersweet" 
                              size="small"
                              onClick={() => deleteFile(path.Key)}
                            >
                              <span className="material-symbols-outlined">delete</span> Delete
                            </OzButton>
                          </RowButton>
                        </>
                        }
                      </TreeItemButtons>
                    </TreeItemRow>
                  </TreeItem>
                : null
              ))}
            </>
          : null}
        
        </>          
  )
}

export default TreeFiles;