import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class UsersService extends ApiCore {

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/users'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/users/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdate(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/users/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminCreate(planUuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/users/'+planUuid+'?create=1', {
        ...params,
        "locale": "en"
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDelete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/users/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminChangePlan(uuid, planUuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/admin/users/'+uuid+'/changePlan/'+planUuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new UsersService();
export default service;