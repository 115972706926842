import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;

const FormNewPasswordNoCode = ({sessionUser, onConfirm, onError, error}) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');


  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>New password</OzTypo>
      <>Please fill in the form to reset your password.</>

      <OzRow>
        <OzCol widthmd="12">
          <OzInput
            label={"New password"}
            name="password1"
            type="password"
            width="100%"
            value={password1}
            onChange={(val) => setPassword1(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Confirm password"}
            name="password2"
            type="password"
            width="100%"
            value={password2}
            onChange={(val) => setPassword2(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => 
            password1 === password2 ?
              onConfirm({
                action: "new-password-nocode",
                params: {
                  password1: password1,
                  password2: password2
                }
              })
            :
              onError("Entered passwords do not match.")
          }>Confirm</OzButton>
        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormNewPasswordNoCode;
