import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import { OzButton } from '@ozwol/webui';

import Meta from '../components/Meta';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #F6F7FB;
`;

const Content = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-size: 100px;
  font-weight: 600;
  color: #8f8f8f;
  line-height: 100px;
`;
const Description = styled.div`
  font-size: 28px;
  font-weight: 300;
  color: #8f8f8f;
`;

const NotAuthorized = () => {
  return (
    <Wrapper>
      <Meta title={"Not authorized"} />
      <Content>
        <Title>401</Title>
        <Description>Not authorized</Description>
        <br/><br/>
        <Link to="/">
          <OzButton variant="bittersweet" size="big">Back to home</OzButton>
        </Link>
      </Content>
    </Wrapper>
  )
}

export default NotAuthorized;
