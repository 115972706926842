//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzSelect, OzCheckbox, OzCol, OzRow, OzInput, OzCard} from "@ozwol/webui";

import PlansService from '../../services/PlansService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';

import {formatError, testValidField} from '../../helpers';



const InsiderPlansModalNew = () => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    rechargePrice: "0",
    rechargeCredits: "0",
    isCustom: true
  });

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const save = () => {
    PlansService.adminCreate(item).then((response) => {
      window.location.replace("/insider/plans/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  };

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.planType, true)){
        valid = false;
      }
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.credits, true)){
        valid = false;
      }
      if(item.planType !== "COMPLIMENTARY"){
        if(!testValidField(item.price, true)){
          valid = false;
        }
        if(!testValidField(item.periodDuration, true)){
          valid = false;
        }
        if(!testValidField(item.rechargePrice, true)){
          valid = false;
        }
        if(!testValidField(item.rechargeCredits, true)){
          valid = false;
        }
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  return (
    <OzContainer>
      
      <OzCard
        headerLeft={
          <CardHeaderTitle title={"Add plan"} />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
        footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
      >

        <OzRow>
          <OzCol widthmd="12">
            <OzSelect
              label="Type"
              width="100%"
              placeholder={"Type"}
              values={[
                {
                  "name": "Paid",
                  "value": "PAID"
                },
                {
                  "name": "Complimentary",
                  "value": "COMPLIMENTARY"
                }
              ]}
              selected={item && item.planType ? item.planType : null}
              highlighted={item && item.planType && item.planType.length > 0}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.planType = val;
                setItem(tempItem);
              }}
              maxHeight={"200px"}
              required
            />
          </OzCol>

          {item && item.planType !== "COMPLIMENTARY" ?
            <OzCol widthmd="12">
              <OzSelect
                label="Duration"
                width="100%"
                placeholder={"Type"}
                values={[
                  {
                    "name": "Monthly",
                    "value": "MONTHLY"
                  },
                  {
                    "name": "Semiannually",
                    "value": "SEMIANNUALLY"
                  },
                  {
                    "name": "Annually",
                    "value": "ANNUALLY"
                  }
                ]}
                selected={item && item.periodDuration ? item.periodDuration : null}
                highlighted={item && item.periodDuration && item.periodDuration.length > 0}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.periodDuration = val;
                  setItem(tempItem);
                }}
                maxHeight={"200px"}
                required
              />
            </OzCol>
          : null }

          <OzCol widthmd="6">
            <OzInput
              label="Name"
              width="100%"
              value={item && item.name ? item.name : ""}
              highlighted={item && item.name && item.name.length > 0}
              placeholder="Name"
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.name = val;
                setItem(tempItem);
              }}
              required
            />
          </OzCol>
          <OzCol widthmd="6">
            <OzCheckbox
              label="Custom"
              labelSpacing
              selected={item && item.isCustom}
              highlighted={item && !item.isCustom}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.isCustom = val;
                setItem(tempItem);
              }}
            />
          </OzCol>
         
          {item && item.planType !== "COMPLIMENTARY" ?
            <OzCol widthmd="6">
              <OzInput
                label="Price"
                width="100%"
                value={item && item.price ? item.price : ""}
                highlighted={item && item.price && item.price.length > 0}
                placeholder="Price"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.price = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
          : null }
          <OzCol widthmd="6">
            <OzInput
              label="Credits"
              width="100%"
              value={item && item.credits ? item.credits : ""}
              highlighted={item && item.credits && item.credits.length > 0}
              placeholder="Credits"
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.credits = val;
                setItem(tempItem);
              }}
              required
            />
          </OzCol>
          {item && item.planType === "PAID" ?
            <>
              <OzCol widthmd="6">
                <OzInput
                  label="Recharge price"
                  width="100%"
                  value={item && item.rechargePrice ? item.rechargePrice : ""}
                  highlighted={item && item.rechargePrice && item.rechargePrice.length > 0}
                  placeholder="Recharge price"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.rechargePrice = val;
                    setItem(tempItem);
                  }}
                  required
                />
              </OzCol>
              <OzCol widthmd="6">
                <OzInput
                  label="Recharge credits"
                  width="100%" 
                  value={item && item.rechargeCredits ? item.rechargeCredits : ""}
                  highlighted={item && item.rechargeCredits && item.rechargeCredits.length > 0}
                  placeholder="Recharge credits"
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.rechargeCredits = val;
                    setItem(tempItem);
                  }}
                  required
                />
              </OzCol>
            </>
          : null }
          
        </OzRow>
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
        
      
      </OzCard>
    </OzContainer>
  );

}
export default InsiderPlansModalNew;
