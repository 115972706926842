import React from 'react';
import styled from 'styled-components';

import {OzLoading} from "@ozwol/webui";

import Error from './Error';

const Loader = styled.div`
  margin: 70px 0px;
`;

const HealthyWrapper = ({loading, error, children}) => {
  if(error){
    return (
      <Error>
        {error}
      </Error>
    )
  }else if(loading){
    return (
      <Loader>
        <OzLoading />
      </Loader>
    )
  }else{
    return children;
  }
	
}

export default HealthyWrapper;
