import React, {useEffect, useCallback} from 'react';
import styled from 'styled-components';

import {BORDER, CHETWODEBLUE, OzContainer, OzRow, OzCol, OzCard} from "@ozwol/webui";

// import CoworkersService from '../../services/CoworkersService';

// import Success from '../../components/Success';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';

// import {formatError} from '../../helpers';


const Role = styled.div`
  border-radius: 4px;
  position: relative;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 16px;
  background-color: ${CHETWODEBLUE};
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
`;
const NoCard = styled.div`
  padding: 20px 30px 30px;
  border: 1px solid transparent;
`;
const Detail = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid ${BORDER};
  white-space: ${props => props.multiline ? "wrap" : "nowrap"};
  width: 100%;
  height: 39px;

  & span{
    font-size: 22px;
  }
`;
const Note = styled.div`
`;

const Permissions = ({refScrollerPage}) => {
  // const [list, setList] = useState(null);

  // const [fetchError, setFetchError] = useState(false);



  const getList = useCallback(() => {
    // CoworkersService.list().then((response) => {
    //   setList(response.result);
    // }).catch((error) => {
    //   setFetchError(formatError(error));
    // });
  }, []);


  useEffect(() => {
    getList();
  }, [getList]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Permission detail"} />
      <PageHeader
        breadcrumbsIcon="passkey"
        breadcrumbsText={["Permission detail", "Team"]}
        refScrollerPage={refScrollerPage}
      />
        <OzRow>
          <OzCol widthmd="3">
          </OzCol>
          <OzCol widthmd="3">
            <Role>Admin</Role>
          </OzCol>   
          <OzCol widthmd="3">
            <Role>Finance</Role>
          </OzCol>  
          <OzCol widthmd="3">
            <Role>Operator</Role>
          </OzCol>       
        </OzRow>  
  
        <OzRow>
          <OzCol widthmd="3">
            <NoCard>
              <Detail multiline={false}>Project workspace *</Detail>
              <Detail multiline={false}>Project settings & logs *</Detail>
              <Detail multiline={false}>Billing and Payment </Detail>
              <Detail multiline={false}>Coworkers section </Detail>
              <Detail multiline={false}>Email notification</Detail>
            </NoCard>
          </OzCol>
        
          <OzCol widthmd="3">
            <OzCard variant={"default"}>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
            </OzCard>
          </OzCol>   
          <OzCol widthmd="3">
            <OzCard variant={"default"}>
              <Detail multiline={false}></Detail>
              <Detail multiline={false}><center>Read only</center></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}></Detail>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
            </OzCard>
          </OzCol>  
          <OzCol widthmd="3">
            <OzCard variant={"default"}>
              <Detail multiline={false}><center><span className="material-symbols-outlined">check</span></center></Detail>
              <Detail multiline={false}><center>Read only</center></Detail>
              <Detail multiline={false}></Detail>
              <Detail multiline={false}></Detail>
              <Detail multiline={false}></Detail>
            </OzCard>
          </OzCol>   

          
          <OzCol widthmd="12">
            <NoCard>
              <Note>* assigned project only</Note>
            </NoCard>
          </OzCol>    
        </OzRow>    
        

    </OzContainer>
  );
}

export default Permissions;
