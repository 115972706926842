import React from 'react';
import styled from 'styled-components';

import {BITTERSWEET, OzCard} from "@ozwol/webui";

const Text = styled.div`
  color: ${BITTERSWEET};
  background: #FFE2E2;
  padding: 6px 10px;
`;

const ErrorPopup = ({children}) => {
  return (
    <OzCard>
      {children ? 
        <Text>
          {children}
        </Text>
      : null }
    </OzCard>  
  )

}

export default ErrorPopup;
