import ApiCore from './ApiCore';
import axios from 'axios';
import {queryParams} from '../helpers';

class HotfoldersService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/hotfolders'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/hotfolders'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/hotfolders/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/hotfolders/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/hotfolders', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminCreate(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/hotfolders', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  update(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/hotfolders/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdate(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/hotfolders/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDelete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/hotfolders/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminClone(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/hotfolders/'+uuid+'/clone',{
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminListActions(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/actions'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminGetActions(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/actions/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  adminCreateActions(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/actions', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdateActions(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/actions/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDeleteActions(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/actions/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  // get(uuid) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.get('backoffice/plans/'+uuid).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // update(uuid, params = null) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.put('backoffice/plans/'+uuid, {
  //       ...params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }


  checkRegex(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/actions/checkRegEx', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  parseRegex(params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/actions/parseRegEx', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  slackify(hotfolder, action, script) {
    let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
    return axios.post('https://hooks.slack.com/services/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', {
      "text": ""+user.ParsedJwtToken.given_name + " " + user.ParsedJwtToken.family_name+" ha condiviso il seguente Hotfolder Script dall'Insider.",
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*"+user.ParsedJwtToken.given_name + " " + user.ParsedJwtToken.family_name+"* ha condiviso il seguente Hotfolder Script dall'Insider."
          }
        },
        {
          "type": "section",
          "fields": [
            {
              "type": "mrkdwn",
              "text": "Hotfolder UUID\n`"+hotfolder.uuid+"`"
            },
            {
              "type": "mrkdwn",
              "text": "Hotfolder Path\n`"+hotfolder.path+"`"
            },
            {
              "type": "mrkdwn",
              "text": "Hotfolder Script UUID\n`"+action.uuid+"`"
            },
            {
              "type": "mrkdwn",
              "text": "Hotfolder Script Name\n`"+action.name+"`"
            },
            {
              "type": "mrkdwn",
              "text": "Script UUID\n`"+script.uuid+"`"
            },
            {
              "type": "mrkdwn",
              "text": "Script Name\n`"+script.name+"`"
            }
          ]
        },
        {
          "type": "divider"
        },
        {
          "type": "header",
          "text": {
            "type": "plain_text",
            "text": "JSON Configuration Hotfolder"
          }
        }
      ]
      
    }, {
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      
      return axios.post('https://hooks.slack.com/services/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', {
        "text": JSON.stringify(action, null, 2)          
      }, {
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        return true;
      });
    });
  }
  
}
let service =  new HotfoldersService();
export default service;
