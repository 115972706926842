import React from 'react';
import styled from 'styled-components';

import {OzCol, OzRow, OzSelect, OzInput, OzDatepicker, OzButton} from '@ozwol/webui';

import {changeDate} from './../helpers';

const Wrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 10px;
`;
const LabelSpacer = styled.div`
  margin-top: 43px;
`;
//
// const TempPicker = styled.div`
//   & .form-control{
//     box-shadow: inset 0px 1px 2px #687FE262;
//     padding: 7.2px 0.75rem;
//   }
// `;

const Filters = ({fields, onChange, onSearch}) => {
  return (
    <Wrapper>
      <OzRow align={"bottom"}>
        {fields.map((field, i) =>
          field ? 
            <OzCol widthmd={field.size} key={i}>
              {(() => {
                switch(field.type) {
                  case "refresh":
                    return <>
                      <LabelSpacer />
                      <OzButton
                        variant="push-black"
                        width={"100%"}
                        value={field.value}
                        onClick={field.callback}
                      >Refresh</OzButton>
                    </>;
                  case "clear":
                    return <>
                      <LabelSpacer />
                      <OzButton
                        variant="push-black"
                        width={"100%"}
                        value={field.value}
                        onClick={field.callback}
                      >Clear</OzButton>
                    </>;
                  case "text":
                    return <>
                      <OzInput
                        label={field.label}
                        width={"100%"}
                        placeholder={field.placeholder}
                        value={field.value}
                        onChange={(val) => onChange(field.name, val)}
                      />
                    </>;
                  case "select":
                    return <>
                      <OzSelect
                        label={field.label}
                        width={"100%"}
                        placeholder={field.placeholder}
                        values={field.options}
                        selected={field.value}
                        onChange={(val) => onChange(field.name, val)}
                        maxHeight={"200px"}
                      />
                    </>;
                  case "searchableselect":
                    return <>
                      <OzSelect
                        label={field.label}
                        width={"100%"}
                        placeholder={field.placeholder}
                        values={field.options}
                        selected={field.value}
                        searchable={true}
                        onChange={(val) => onChange(field.name, val)}
                        onSearch={(val) => onSearch(field.name, val)}
                        maxHeight={"200px"}
                      />
                    </>;
                  case "rangedate":
                    // return <>
                    //   <div>{field.label}</div>
                    //     <OzDatepicker
                    //       label={field.label}
                    //       type="range"
                    //       width={"100%"}
                    //       onChange={(startVal, endVal) => {
                    //         onChange(field.name, (startVal ? changeDate(startVal, 2, "hours", "startOfDay") : null) + "|" + (endVal ? changeDate(endVal, 2, "hours", "endOfDay") : (startVal ? changeDate(startVal, 2, "hours", "startOfDay") : null)));
                    //       }}
                    //       startValue={new Date(field.value.split('|')[0])}
                    //       endValue={changeDate(new Date(field.value.split('|')[1]), -2, "hours")}
                    //     />
                    // </>;
                    return <>
                        <OzDatepicker
                          label={field.label}
                          type="range"
                          shortcuts={true}
                          compact={true}
                          periods={field.periods ? field.periods : null}
                          buttonLabel={<span className="material-symbols-outlined">calendar_month</span>}
                          onChange={(startVal, endVal) => {
                            // if(startVal){
                            //   var timezoneOffset = startVal.getTimezoneOffset();
                            //   if (timezoneOffset > 0) {
                            //     startVal.setMinutes((24 * 60) - (timezoneOffset + 1));
                            //   } else {
                            //     startVal.setMinutes(-timezoneOffset); // Do not forget the negative sign 
                            //   }
                            // }
                            // if(endVal){
                            //   var timezoneOffset = endVal.getTimezoneOffset();
                            //   if (timezoneOffset > 0) {
                            //     endVal.setMinutes((24 * 60) - (timezoneOffset + 1));
                            //   } else {
                            //     endVal.setMinutes(-timezoneOffset); // Do not forget the negative sign 
                            //   }
                            // }
                            onChange(field.name, (startVal ? changeDate(startVal, 0, "days", "startOfDay") : null) + "|" + (endVal ? changeDate(endVal, 0, "days", "endOfDay") : (startVal ? startVal.toISOString() : null)));
                          }}
                          startValue={new Date(field.value.split('|')[0])}
                          endValue={new Date(field.value.split('|')[1])}
                        />
                    </>;
                  default:
                    return null;
                }
              })()}
            </OzCol>
          : null
        )}
      </OzRow>
    </Wrapper>

  );
}

export default Filters;
