import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class ProjectsService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/projects'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/projects'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      if(uuid){
        this.instance.get('backoffice/projects/'+uuid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      }else{
        reject();
      }
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      if(uuid){
        this.instance.get('backoffice/admin/projects/'+uuid).then(response => {
          resolve(response.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      }else{
        reject();
      }
    });
  }

  // create(params) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/projects/', {
  //       ...params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  adminCreate(params, ownerUuid) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/projects?userUuid='+ownerUuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  update(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/projects/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdate(uuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/projects/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDelete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/projects/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getCredentials(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/projects/'+uuid+'/credentials').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getWorkspaceCredentials(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/projects/'+uuid+'/workspaceCredentials').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  addCredentials(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/projects/'+uuid+'/credentials').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deleteCredentials(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/projects/'+uuid+'/credentials').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  fixHotfolders(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/hotfolders/fixAll?project_uuid='+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminFixHotfolders(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/admin/hotfolders/fixAll?project_uuid='+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  // generateS3(params, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/generateS3Credentials', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "project_uuid": params.uuid
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // deleteS3(params, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/deleteS3Credentials', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "project_uuid": params.uuid
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // listApikeys(project_uuid, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/listApikeys', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "project_uuid": project_uuid
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // putApikey(project_uuid, owner_uuid, params, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/putApikey', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "project_uuid": project_uuid,
  //       "attributes": params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // updateApikey(params, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/updateApikey', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "apikey_uuid": params.uuid,
  //       "attributes": params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // deleteApikey(params, retry = true) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/deleteApikey', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       "apikey_uuid": params.uuid
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }
}

let service =  new ProjectsService();
export default service;