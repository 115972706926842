import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class DestinationsService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/destinations'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/destinations/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  create(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/destinations', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  update(uuid, params = null) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/destinations/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  delete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/destinations/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/destinations'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  // adminGet(uuid) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.get('backoffice/admin/destinations/'+uuid).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // adminCreate(params = null) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/admin/destinations'+queryParams(params), {
  //       ...params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // adminUpdate(uuid, params = null) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.put('backoffice/admin/destinations/'+uuid, {
  //       ...params
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  // adminDelete(uuid) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.delete('backoffice/admin/destinations/'+uuid).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  test(item) {
    let endpoint = "backoffice/destinations/";
    if(item.uuid !== undefined){
      endpoint += item.uuid+"/";
    }
    endpoint += "test";
    return new Promise((resolve, reject) => {
      this.instance.patch(endpoint, item).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listRegions(destinationType) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/destinations/'+destinationType+'/regions').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  generateSSHKeys() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/destinations/generateSSHKeys').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
}
let service =  new DestinationsService();
export default service;
