import React, {useState, useCallback, useEffect, useContext} from 'react';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from '../../../contexts/OverlayContext';

import {CHETWODEBLUE} from "@ozwol/webui";

const Wrapper = styled.div`
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
`;
// const Loader = styled.div`
//   float: right;
// `;
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0px;
  gap: 8px;
`;
const Name = styled.div`
  width: 130px;
  font-size: 11px;
`;
const Bar = styled.div`
  position: relative;
  // border: 1px solid black;
  height: 5px;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #ffffff;


  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${props => props.value}%;
    height: 100%;
    background-color: ${CHETWODEBLUE};
    transition: width 0.25s ease-out;
  }
`;


const UploadPolling = () => {
  const {setPopup} = useContext(OverlayContext);

  const [count, setCount] = useState(0);
  const [text, setText] = useState("");


	const check = useCallback(() => {
    let status = localStorage.getItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
    if(!status){
      status = {};
    }else{
      status = JSON.parse(status);
    }
    // console.log(status);

    let tempStatus = JSON.parse(JSON.stringify(status));
    Object.keys(tempStatus).forEach(key => {
      if(tempStatus[key]["lastUpdate"] + (10*60*1000) < new Date()){ //30min x 60sec x 1000 millisec
        // console.log(tempStatus[key]["lastUpdate"]);
        delete tempStatus[key];
      }else{
      }
    });
    localStorage.setItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(tempStatus));

    if(status && Object.keys(status).length > 0){
      setText(<>
        {Object.keys(status).sort((keyA, keyB) => status[keyB]["percent"] - status[keyA]["percent"]).map((key) => <Row key={key}><Name>{key.substring(key.lastIndexOf('/') + 1)}</Name><Bar value={status[key]["percent"]} /></Row>)}
      </>);
      setCount(Object.keys(status).length);
      setTimeout(() => {check()}, 250)
    }else{
      setPopup(null);
    }

    
  }, [setPopup]);  
 

  useEffect(() => {
		check();
  }, [check]);

  return (
    <Wrapper>
      Uploading {count} files... <br/><br/>
      {text}
    </Wrapper>
  )
}

export default UploadPolling;
