import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class PlansService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('public/plans'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/plans'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/plans/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminCreate(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/plans', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdate(uuid, params = null) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/plans/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDelete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/plans/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  getPrices() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/scripts/priceList').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  listUpdatableFields(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/plans/'+uuid+'/updatableAttributes').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
}
let service =  new PlansService();
export default service;
