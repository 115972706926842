import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;
const Cta = styled.span`
  color: ${CHETWODEBLUE};
  cursor: pointer;
`;

const FormLogin = ({enableRegistration, onConfirm, error}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Sign in</OzTypo>
      <>Enter your credentials to log in.</>

      <OzRow>
        <OzCol widthmd="12">
          <OzInput
            label={"Email"}
            name="email"
            type="email"
            width="100%"
            value={email}
            onChange={(val) => setEmail(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Password"}
            name="password"
            type="password"
            width="100%"
            value={password}
            onChange={(val) => setPassword(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => onConfirm({
            action: "login",
            params: {
              email: email,
              password: password
            }
          })}>Sign in</OzButton>

        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
        <OzCol widthmd="12">
          {enableRegistration ?
            <>
              Don't have an account yet? <Cta onClick={() => onConfirm({
                action: "navigate",
                params: {
                  page: "registration"
                }
              })}>Sign up</Cta>.<br/>
            </>
          : null }
          Have you forgotten your password? <Cta onClick={() => onConfirm({
            action: "navigate",
            params: {
              page: "forgot-password"
            }
          })}>Recover it</Cta>.
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormLogin;
