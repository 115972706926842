import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class ApiKeysService extends ApiCore {

  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/apikeys'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/apikeys/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  create(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/apikeys', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  update(uuid, params = null) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/apikeys/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  delete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/apikeys/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/apikeys'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/apikeys/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminCreate(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/admin/apikeys'+queryParams(params), {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminUpdate(uuid, params = null) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/admin/apikeys/'+uuid, {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  adminDelete(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/admin/apikeys/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
}
let service =  new ApiKeysService();
export default service;
