import ApiCore from './ApiCore';
import {queryParams} from '../helpers';


class AccountService extends ApiCore {

  createUser(planUuid, params) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/users/'+planUuid, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getUser() {
    // let user = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
    // if(user["JwtToken"]["cognito:groups"].includes("OzwolCoworker")){
    //   return new Promise((resolve, reject) => {
    //     this.instance.get('backoffice/coworkers/'+user["JwtToken"].sub).then(response => {
    //       resolve(response.data.results[0]);
    //     }).catch(error => {
    //       reject(error);
    //     });
    //   });
    // }else{
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/users').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
    // }    
  }

  getCoworker(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/coworkers/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getPermissions() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/users/permissions').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateUser(params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.put('backoffice/users', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  changePlan(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/users/changePlan/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  beginPayment(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/users/beginPayment/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  closeAccount() {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/users/close').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  reopenAccount() {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/users/reopen').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }


  getPeriod(params = null, index = -1) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/periods/'+index+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listPeriods(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/periods'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listUsages(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/usages'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listNotificationEvents() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/users/notificationEvents').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /*Payment Methods*/

  createCustomer(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/payments/customer').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  listPaymentMethods(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/payments'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  createPaymentMethodCard(params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/payments/card', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  createPaymentMethodSepaIntent(params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/payments/sepa', {
        ...params
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deletePaymentMethod(id) {
    return new Promise((resolve, reject) => {
      this.instance.delete('backoffice/payments/'+id).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getDefaultPaymentMethod() {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/payments/getDefault').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  setDefaultPaymentMethod(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.post('backoffice/payments/setDefault/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  initPaidPlan(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.patch('backoffice/users/initPaidPlan/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  // listRecharges(params = {}) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.post('backoffice/getRecharges', {
  //       "impersonate": localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))),
  //       ...params  
  //     }).then(response => {
  //       resolve(response.data);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }
}

let service =  new AccountService();
export default service;
