import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';
import {CDN} from '../../config';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzButton, OzCopyable} from "@ozwol/webui";

import DestinationsService from '../../services/DestinationsService';
import ProjectsService from '../../services/ProjectsService';

import Meta from '../../components/Meta';
import ErrorPopup from '../../components/ErrorPopup';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import PromptedButton from '../../components/PromptedButton';

import DestinationsModalNew from './DestinationsModalNew';
import DestinationsModalEdit from './DestinationsModalEdit';

import {checkPermission, formatError} from '../../helpers';


const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;
const NoResult = styled.div`
  text-align: center;
  font-weight: bold;

  & > img{
  width: 40%;

`;


const Destinations = ({refScrollerPage}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [list, setList] = useState(null);
  const [listProjects, setListProjects] = useState(null);

  const [fetchError, setFetchError] = useState(false);



  const getList = useCallback(() => {
    DestinationsService.list().then((response) => {
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, []);

  const deleteItem = useCallback((item) => {
    // setList(null);
    DestinationsService.delete(item.uuid).then((response) => {
      getList();
    }).catch((error) => {
      setOverlay(<ErrorPopup>{formatError(error)}</ErrorPopup>);
      setTimeout(() => {
        setOverlay(null);
      }, 10000);
    });
  }, [getList, setOverlay]);

  // const copyItem = useCallback((uuid) => {
  //   DestinationsService.get(uuid).then((response) => {
  //     delete response.uuid;
  //     delete response.connectionDetails.credentials;
  //     response.name += " (copy without credentials)"
  //     console.log(response);
  //     DestinationsService.create(response).then(() => {
  //       setOverlay(<Success>Your data has been successfully saved!</Success>);
  //       setTimeout(() => {
  //         setOverlay(null);
  //       }, 2000);
  //       getList();
  //     }).catch((error) => {
  //       setList(null);
  //       setFetchError(formatError(error));
  //     });      
  //   }).catch((error) => {
  //     setList(null);
  //     setFetchError(formatError(error));
  //   });
  // }, [getList]);

  const copyableData = (item) => {   
    let tempItem = JSON.parse(JSON.stringify(item));
    tempItem.name += " (copy)"
    delete tempItem.uuid;
    delete tempItem.connectionDetails.credentials.awsAccessKeyId;
    delete tempItem.connectionDetails.credentials.awsSecretAccessKey;
    delete tempItem.connectionDetails.credentials.accessKeyId;
    delete tempItem.connectionDetails.credentials.secretAccessKey;
    delete tempItem.connectionDetails.credentials.accessKey;
    delete tempItem.connectionDetails.credentials.user;
    delete tempItem.connectionDetails.credentials.password;
    delete tempItem.connectionDetails.credentials.publicKey;
    delete tempItem.connectionDetails.credentials.privateKey;
    return tempItem;
  };

  useEffect(() => {
    getList();
    ProjectsService.list().then((response) => {
      setListProjects(response.result);
    });
  }, [getList]); 

  // const getProject = useCallback(() => {
  //   ProjectsService.get(projectUuid).then((response) => {
  //     setProject(response);
  //   }).catch((error) => {
  //     setProject(null);
// setFetchError(formatError(error));
  //   });
  // }, [projectUuid]);

  // const updateProject = useCallback(() => {
  //   setErrorMessage(null);
  //   setDisabled(true);
  //   ProjectsService.update(projectUuid, clearObject(project, ["name","description","color"])).then((response) => {
  //     setOverlay(<Success>Your data has been successfully saved!</Success>);
  //     setTimeout(() => {
  //       setOverlay(null);
  //     }, 2000);
  //     // setProject(response);
  //   }).catch((error) => {
      // setErrorMessage(formatError(error));
  //   });
  // }, [projectUuid, project, setOverlay]);

  // useEffect(() => {
  //   getProject();
  // }, [projectUuid, getProject]);

  // useEffect(() => {
  //   setErrorMessage(null);
  //   let valid = true;
  //   if(project){
  //     if(!testValidField(project.name, true)){
  //       valid = false;
  //     }
  //     if(!testValidField(project.color, true)){
  //       valid = false;
  //     }
  //   }else{
  //     valid = false;
  //   }
  //   setDisabled(!valid);
  // }, [project]);

  return (
    <OzContainer size="extra">
      <Meta title={"Destinations"} />
      <PageHeader
        breadcrumbsIcon="near_me"
        breadcrumbsText={["Overview", "Destinations"]}
        refScrollerPage={refScrollerPage}
      />
  
      
      <HealthyWrapper error={fetchError} loading={!list || !listProjects}>
        <OzRow>
          <OzCol>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Destinations"}
                />
              }
              headerSeparator={true}
              footerRight={checkPermission("destination", "create") ? <OzButton onClick={() => setOverlay(<DestinationsModalNew projects={listProjects} onSuccess={() => getList()} />)} variant={"chetwodeblue"}>Add</OzButton> : null}
            >
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Name",
                        "Description",
                        "Destination type",
                        "UUID",
                        // "Active",
                        ""
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.name,
                            item.description,
                            item.connectionDetails.destinationType,
                            <OzCopyable>{item.uuid}</OzCopyable>,
                            //<OzTag variant={item.active ? "silvertree" : "bittersweet"}>{item.active ? "Yes" : "No"}</OzTag>,
                            <>
                              {checkPermission("destination", "create") ? 
                                <>
                                  <OzButton onClick={() => setOverlay(<DestinationsModalNew data={copyableData(item)} projects={listProjects} onSuccess={() => getList()} />)} noMinWidth={true} variant="push-black" size="small"><span className="material-symbols-outlined">content_copy</span></OzButton>
                                  &nbsp;
                                </>
                              : null}
                              {checkPermission("destination", "update") ? 
                                <>
                                  <OzButton onClick={() => setOverlay(<DestinationsModalEdit uuid={item.uuid} projects={listProjects} onSuccess={() => getList()} />)} noMinWidth={true} variant="push-black" size="small"><span className="material-symbols-outlined">edit</span></OzButton>
                                  &nbsp;
                                </>
                              : null}
                              {checkPermission("destination", "delete") ? 
                                <PromptedButton 
                                  buttonVariant="push-bittersweet" 
                                  buttonSize={"small"} 
                                  buttonText={<span className="material-symbols-outlined">delete</span>} 
                                  prompt="DELETE"
                                  buttonNoMinWidth={true}
                                  onConfirm={() => deleteItem(item)} 
                                /> 
                              : null}
                              
                            </>
                          ]
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : 
                <NoResult>
                  <img src={CDN + "console-assets/no-generic.svg"} alt="no api keys" /><br/><br/>
                  There are no active Destinations.<br/>
                  Click Add to get started.
                </NoResult>
              }   

            </OzCard>
          </OzCol>
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  );
}

export default Destinations;
