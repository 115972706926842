import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';

import {OzContainer, OzRow, OzCol, OzCard, OzTable, OzTag, OzButton} from '@ozwol/webui';

import DownloadService from '../../services/DownloadService';

import Filters from '../../components/Filters';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import NoResult from '../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, getFormattedDateTime} from './../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2){
    word-break: break-all;
    white-space: unset;
  }
`;

const WorkspaceDownloads = ({refScrollerPage}) => {
  const [page, setPage] = useState(0);
  const [list, setList] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  const DEFAULT_FILTERS = {
    "status": null
  };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterDownloads"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterDownloads"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );

  const changeFilters = useCallback((name, value) => {
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    DownloadService.list({
      "status": filters.status
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterDownloads"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  useEffect(() => {
    getList();
  }, [getList, page, filters]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Workspace - Downloads"} />
      <PageHeader
        breadcrumbsIcon="download"
        breadcrumbsText={["Workspace", "Downloads"]}
        refScrollerPage={refScrollerPage}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Downloads"}
                />
              }
              headerSeparator={true}
            >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                fields={
                  [
                    {
                      "label": "Status",
                      "name": "status",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.status,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Queued",
                          "value": "QUEUED"
                        },{
                          "name": "Ongoing",
                          "value": "ONGOING"
                        },{
                          "name": "Success",
                          "value": "SUCCESS"
                        },{
                          "name": "Error",
                          "value": "ERROR"
                        },{
                          "name": "Cancelled",
                          "value": "CANCELLED"
                        }
                      ]
                    },{
											"size": 2,
											"type": "refresh",
											"callback": () => {
												getList();
											}
										},{
                      "size": 2,
                      "type": "clear",
                      "callback": () => {
                        setFilters(DEFAULT_FILTERS);
                        // localStorage.removeItem("ozwolConsoleFilterDownloads"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                      }
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Status",
                        "Name",
                        "Create datetime",
                        "Expiry datetime",
                        "Files",
                        ""
                      ]}
                      values={
                        list.map(item => ({
                          values: [
                            item.status === "QUEUED" ? 
                              <OzTag variant={"mustard"}>Queued</OzTag>
                            : item.status === "ONGOING" ? 
                              <OzTag variant={"silvertree"}>Ongoing</OzTag>
                            : item.status === "SUCCESS" ? 
                              <OzTag variant={"silvertree"}>Success</OzTag>
                            : item.status === "ERROR" ? 
                              <OzTag variant={"bittersweet"}>Error</OzTag>
                            : item.status === "CANCELLED" ? 
                              <OzTag variant={"bittersweet"}>Cancelled</OzTag>
                            : null,
                            item.downloadFilename	,
                            getFormattedDateTime(item.createDatetime),
                            getFormattedDateTime(item.expiryDatetime),
                            item.fileList.length,
                            item.downloadUrl ? 
															<a href={item.downloadUrl} rel="noreferrer">
                                <OzButton size="small" variant="push-black">Download</OzButton>
                              </a>
														:
															null,
                          ]
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default WorkspaceDownloads;
