//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzSelect, OzCol, OzRow, OzInput, OzCard, OzProjectTag} from "@ozwol/webui";

import HotfoldersService from '../../services/HotfoldersService';
import ProjectsService from '../../services/ProjectsService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import WarningMessage from '../../components/WarningMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, testValidField} from '../../helpers';



const InsiderHotfoldersModalDuplicate = ({
  source
}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    path: source && source.path ? source.path.replace("HOTFOLDERS/","") : "",
    projectUuid: source && source.projectUuid ? source.projectUuid : "",
    description: source && source.description ? source.description : "",
  });
  const [originalItem] = useState({
    path: source && source.path ? source.path.replace("HOTFOLDERS/","") : "",
    projectUuid: source && source.projectUuid ? source.projectUuid : "",
    description: source && source.description ? source.description : "",
  });

  const [searchProjects, setSearchProjects] = useState("");
  const [listProjects, setListProjects] = useState([]);

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const clone = () => {
    let salvageableItem = {...item}
    salvageableItem.path = "HOTFOLDERS/" + item.path;
    HotfoldersService.adminClone(source.uuid, salvageableItem).then((response) => {
      window.location.replace("/insider/hotfolders/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  };

  useEffect(() => {
    ProjectsService.adminList({
      "search": searchProjects.length > 2 ? searchProjects : "",
    }).then((response) => {
      setListProjects(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchProjects]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.projectUuid, true)){
        valid = false;
      }
      if(!testValidField(item.path, true)){
        valid = false;
      }
      if("HOTFOLDERS/"+item.path === source.path && item.projectUuid === source.projectUuid ){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [source, item, originalItem]);

  return (
    <OzContainer>
      <HealthyWrapper error={fetchError}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Duplicate hotfolder"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Back</OzButton>}
          footerRight={<OzButton onClick={() => clone()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="12">
              <OzSelect
                label="Project"
                width="100%"
                placeholder="Project"
                selected={item && item.projectUuid ? item.projectUuid : null}
                highlighted={item && originalItem && item.projectUuid !== originalItem.projectUuid}
                values={listProjects.map((item) => ({
                  "name": <OzProjectTag
                      name={item.name}
                      color={item.color}
                      uuid={item.uuid}
                      direction={"horizontal"}
                    />,
                  "value": item.uuid,
                }))}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.projectUuid = val;
                  setItem(tempItem);               
                }}
                maxHeight={"200px"}
                searchable={true}
                search={searchProjects}
                onSearch={(val) => setSearchProjects(val)}
                required
              />
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Path"
                preLabel={"HOTFOLDERS/"}
                width="100%"
                value={item && item.path ? item.path : ""}
                highlighted={item && originalItem && item.path !== originalItem.path}
                placeholder="Path"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.path = val.replaceAll(" ","_");
                  tempItem.description = val.replace("INPUT/","");
                  setItem(tempItem);
                }}
                required
              />
              <WarningMessage>{!item.path.startsWith("INPUT/") ? "Warning: without INPUT/ some regexes may not work correctly." : null }</WarningMessage>              
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Description"
                width="100%"
                value={item && item.description ? item.description : ""}
                highlighted={item && originalItem && item.description !== originalItem.description}
                placeholder="Description"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.description = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderHotfoldersModalDuplicate;
