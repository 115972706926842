import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {GHOSTWHITE, CHETWODEBLUE, SILVERTREE, OzButton, OzContainer, OzRow, OzCol, OzCard, OzIcon, OzTable, OzProjectTag, OzChartHistogram} from '@ozwol/webui';

import Meta from '../../components/Meta';

import UsersService from '../../services/UsersService';
import ProjectsService from '../../services/ProjectsService';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';

import {formatError} from './../../helpers';

const CardGroup = styled.div`
  &>*:not(:first-child){
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 3px solid ${GHOSTWHITE};
  }
  &>*:not(:last-child){
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;


// const Header = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 40px;
//   margin-bottom: 40px;
// `;


const CardPhrase = styled.div`

  display: flex;
  align-items: center;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;

  display: flex;
  align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
`;

const Detail = styled.div`
  text-transform: uppercase;
  color: ${CHETWODEBLUE};
  font-size: 12px;
`;

const PlanRecap = styled.div`
  display: flex;
  flex-direction: row;
`;
const PlanRecapNames = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  font-size: 10px;
`;
const PlanRecapName = styled.div`
  height: 16px;
  margin-bottom: 2px;
  margin-right: 10px;
`;
const PlanRecapValues = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const PlanRecapValue = styled.div`
  position: relative;
  height: 16px;
  margin-bottom: 2px;

  &::before{
    display: block;
    content: "";
    position: absolute;
    top: 8px;
    width: 100%;
    height: 0;
    border-bottom: 2px dotted ${GHOSTWHITE};
  }
`;

const PlanRecapBar = styled.div`
  position: absolute;
  top: 8px;
  width: ${props => 100 - (props.daysLeft * 100 / 31)}%;
  height: 0;
  border-bottom: 2px dashed ${props => props.daysLeft > 0 ? CHETWODEBLUE : "transparent"};

  &::before{
    display: ${props => props.daysLeft > 0 ? "block" : "none"};
    content: "${props => props.daysLeft} days left";
    position: absolute;
    top: -7px;
    left: calc(100% + 8px);
    border-radius: 9px;
    font-size: 10px;
    white-space: nowrap;
  }

  &::after{
    display: block;
    content: "";
    position: absolute;
    top: -2px;
    right: -4px;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: ${props => props.daysLeft > 0 ? CHETWODEBLUE : SILVERTREE};
  }
`;


const InsiderOverview = ({refScrollerPage}) => {

  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);

  const [fetchError, setFetchError] = useState(false);

  const scrollToCredits = useRef();

  useEffect(() => {
    ProjectsService.adminList().then((response) => {
      setProjects(response.result.slice(0,10));
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    UsersService.adminList().then((response) => {
      setUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, []);


  return (
    <OzContainer size="extra">
      <Meta title={"Overview"} />
      <PageHeader
        breadcrumbsIcon="mountain_flag"
        breadcrumbsText={["Overview"]}
        refScrollerPage={refScrollerPage}
        anchors={[
          {
            "icon": "toll",
            "ref": scrollToCredits
          }
        ]}
      />
      <HealthyWrapper error={fetchError} loading={!projects || !users}>
        {projects && users ?
          <OzRow>
            <OzCol>
              <CardGroup>
                <OzCard
                  height="100%"
                  headerLeft={<CardHeaderTitle title="Daily usage" />}
                  headerRight={
                    <CardPhrase>
                      <CardPhraseText>
                        Totale assets processati
                        <CardPhraseSuperNumber>
                          2.093
                        </CardPhraseSuperNumber>
                      </CardPhraseText>
                      <OzIcon name={"assets"} size="25px" />
                    </CardPhrase>
                  }
                >

                  <OzChartHistogram
                    stretch={false}
                    fnCallback={(ret) => alert(JSON.stringify(ret))}
                    values={[...new Array(24)].map((nothing, i) => ({
                      id: i,
                      label: i+":00",
                      bars: projects.map((item, j) => ({
                        id: i+"-"+j,
                        value: 1,//Math.floor(Math.random() * (100 + 50 + 1) - 50),
                        style: {
                          barColor: item.color
                        }
                      }))
                    }))}
                  />

                </OzCard>
                <OzCard
                  height="100%"
                  headerLeft={
                    <CardHeaderTitle 
                      title="Utilizzo della piattaforma per Cliente" 
                      subtitle="Today" 
                    />
                  }
                  headerRight={
                    <CardPhrase>
                      <CardPhraseText>
                        Clienti operativi/account attivi
                        <CardPhraseSuperNumber>
                          5/16
                        </CardPhraseSuperNumber>
                      </CardPhraseText>
                      <OzIcon name={"user"} size="25px" />
                    </CardPhrase>
                  }
                >

                  
                  <OzTable
                    columns={[
                      "Start Time",
                      "Project",
                      "Nomecliente",
                      "Assets",
                      "Credits",
                      "Error",
                      "Impersonification"
                    ]}
                    values={projects.map((item, i) => (({values: [
                      "08:12",
                      <OzProjectTag
                        name={item.name}
                        uuid={item.uuid}
                        color={item.color}
                      />,
                      item.owner_uuid,
                      "345",
                      "12345",
                      "-",
                      <OzButton onClick={() => {
                        window.open(item.owner_uuid, '_blank').focus();                        
                      }} icon={"impersonification"} variant={"push-black"} />
                    ]})))}
                  />
                </OzCard>
              </CardGroup>
            </OzCol>
            <OzCol>
              { projects ?
                <OzCard
                  height="100%"
                  headerLeft={
                    <CardHeaderTitle 
                      title="Ultimi assets inviati" 
                    />
                  }
                  headerRight={
                    <CardPhrase>
                      <CardPhraseText>
                        <CardPhraseSuperNumber>
                          37 minuti
                        </CardPhraseSuperNumber>
                      </CardPhraseText>
                      <OzIcon name={"assets"} size="25px" />
                    </CardPhrase>
                  }
                  footerRight={
                    <Link to={"/recharges"}>
                      <Detail>Detail</Detail>
                    </Link>
                  }
                >                  
                  <OzTable
                  columns={[
                    "Date",
                    "Time",
                    "Project",
                    "File name",
                    "Script",
                    "Channel",
                    "Size"
                  ]}
                  values={projects.map((item, i) => (({values: [
                    "14/12/2021",
                    "08:12:55",
                    <Link to={"/" + item.uuid + "/dashboard"}>
                      <OzProjectTag
                        name={item.name}
                        uuid={item.uuid}
                        color={item.color}
                      />
                    </Link>,
                    "sdasdasdasdasfdfsfdfvgxd.jpg",
                    "Resize",
                    "CDN",
                    "32,5MB"
                  ]})))}/>
                </OzCard>
              : null }
            </OzCol>
            <OzCol>
              <OzCard
                height="100%"
                headerLeft={
                  <CardHeaderTitle 
                    title="Plan renewal" 
                    subtitle="Usage since June 15, 2021" 
                  />
                }
                headerRight={
                  <CardPhrase>
                    <CardPhraseText>
                      Active clients
                      <CardPhraseSuperNumber>
                        14
                      </CardPhraseSuperNumber>
                    </CardPhraseText>
                    <OzIcon name={"credits"} size="25px" />
                  </CardPhrase>
                }
                footerRight={
                  <Link to={"/recharges"}>
                    <Detail>Detail</Detail>
                  </Link>
                }
              >         

                <PlanRecap>
                  <PlanRecapNames>
                    {users.map((user, i) => 
                      <PlanRecapName key={i}>{user.company}</PlanRecapName>
                    )}
                  </PlanRecapNames>
                  <PlanRecapValues>
                    {users.map((user, i) => 
                      <PlanRecapValue key={i}>
                        <PlanRecapBar daysLeft={Math.floor(Math.random() * (30 + 1))} />
                      </PlanRecapValue>
                    )}
                  </PlanRecapValues>
                </PlanRecap>

                <OzTable
                  columns={[
                    "Expiration date",
                    "Days left",
                    "Client",
                    "Credits spent",
                    "Recharge",
                    "Plan",
                    "Cost"
                  ]}
                  values={users.slice(0,3).map((item, i) => (({values: [
                    "???",
                    "???",
                    item.company,
                    "???",
                    "???",
                    item.current_plan ? item.current_plan.name : "-",
                    item.current_plan ? item.current_plan.price : "-"
                  ]})))}
                />



              </OzCard>
            </OzCol>
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderOverview;
