import React, {useState} from 'react';
import styled from 'styled-components';

import {CHETWODEBLUE, BITTERSWEET, OzRow, OzCol, OzCard, OzButton, OzTypo, OzInput} from "@ozwol/webui";

const Error = styled.div`
  height: 30px;
  color: ${BITTERSWEET};
`;
const Cta = styled.span`
  color: ${CHETWODEBLUE};
  cursor: pointer;
`;

const FormRegistration = ({onConfirm, error}) => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');


  return (

    <OzCard>
      <OzTypo variant="h1c" color={CHETWODEBLUE}>Registration</OzTypo>
      <>Enter your credentials to register.</>

      <OzRow>
        <OzCol widthmd="12">
          <OzInput
            label={"Email"}
            name="email"
            type="email"
            width="100%"
            value={email}
            onChange={(val) => setEmail(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Given Name"}
            name="givenName"
            type="text"
            width="100%"
            value={givenName}
            onChange={(val) => setGivenName(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Family Name"}
            name="familyName"
            type="text"
            width="100%"
            value={familyName}
            onChange={(val) => setFamilyName(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Password"}
            name="password1"
            type="password"
            width="100%"
            value={password1}
            onChange={(val) => setPassword1(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzInput
            label={"Repeat password"}
            name="password2"
            type="password"
            width="100%"
            value={password2}
            onChange={(val) => setPassword2(val)}
            required
          />
        </OzCol>
        <OzCol widthmd="12">
          <OzButton variant={"chetwodeblue"} onClick={() => onConfirm({
            action: "registration",
            params: {
              email: email,
              password1: password1,
              password2: password2,
              givenName: givenName,
              familyName: familyName,
              locale: "it_IT"
            }
          })}>Sign up</OzButton>
        </OzCol>
        <OzCol widthmd="12">
          <Error>
            <br/>{error}<br/>
          </Error>
        </OzCol>
        <OzCol widthmd="12">
          Do you already have an account? <Cta onClick={() => onConfirm({
            action: "navigate",
            params: {
              page: "login"
            }
          })}>Sign in</Cta>.
        </OzCol>
      </OzRow>
    </OzCard>
  )
}

export default FormRegistration;
