import ApiCore from './ApiCore';
import {queryParams} from '../helpers';

class ScriptsService extends ApiCore {

  adminList(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/scripts'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  list(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/scripts'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/scripts/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  adminGet(uuid) {
    return new Promise((resolve, reject) => {
      this.instance.get('backoffice/admin/scripts/'+uuid).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
}

let service =  new ScriptsService();
export default service;