import React, {useState, useEffect, useCallback} from 'react';
// import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable} from '@ozwol/webui';

import Filters from '../../components/Filters';
import Meta from '../../components/Meta';

import ScriptsService from '../../services/ScriptsService';
import PageHeader from '../../components/PageHeader';
import NoResult from '../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, convertNumber} from '../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2){
    word-break: break-all;
    white-space: unset;
  }
`;
const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *{
    margin-left: 5px;
  }
`;
const Icon = styled.div`
  display: flex;
  margin-right: -10px;
  & > span{
    font-size: 20px;
  }
`;

const InsiderScripts = ({refScrollerPage}) => {

  const [page, setPage] = useState(0);
  const [list, setList] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  const [filters, setFilters] = useState({
    "search": "",
    "active": true
  });

  const changeFilters = useCallback((name, value) => {
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    ScriptsService.list({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active
    }).then((response) => {
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  useEffect(() => {
    getList();
  }, [getList, page, filters]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Scripts - Insider"} />
      <PageHeader
        breadcrumbsIcon="amp_stories"
        breadcrumbsText={["Scripts"]}
        refScrollerPage={refScrollerPage}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
                headerLeft={
                  <CardHeaderTitle 
                    title={"Scripts"}
                  />
                }
                headerSeparator={true}
              >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                fields={
                  [
                    {
                      "label": "Search",
                      "name": "search",
                      "size": 4,
                      "type": "text",
                      "placeholder": null,
                      "value": filters.search
                    },{
                      "label": "Status",
                      "name": "active",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.active,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Enabled",
                          "value": true
                        },{
                          "name": "Disabled",
                          "value": false
                        }
                      ]
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "",
                        "Name",
                        "Public",
                        "Active",
                        "Execution Fee",
                        "Minumum Cost",
                        "Maximum Cost",
                        "Unit Cost",
                        "Unit Multiplier",
                        ""
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.isLazy ? <Icon><span className="material-symbols-outlined">bedtime</span></Icon> : null,
                            item.name,
                            item.public ? "Yes" : "No",
                            item.active ? "Yes" : "No",
                            item.price && item.price.execution_fee ? convertNumber(item.price.execution_fee) : "-",
                            item.price && item.price.minimum_cost ? convertNumber(item.price.minimum_cost) : "-",
                            item.price && item.price.maximum_cost ? convertNumber(item.price.maximum_cost) : "-",
                            item.price && item.price.unit_cost ? convertNumber(item.price.unit_cost) : "-",
                            item.price && item.price.unit_multiplier ? convertNumber(item.price.unit_multiplier) : "-",
                            <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                              {/* { item.active ?
                                <OzButton icon="eye-open" variant={"push-bittersweet"} size={"small"} onClick={() => disableItem(item)} />
                              :
                                <OzButton icon="eye-close" variant={"push-black"} size={"small"} onClick={() => enableItem(item)} />
                              } */}
                              {/* <DeleteButton icon="remove" size={"small"} onConfirm={() => deleteItem(item)} />                                   */}
                            </TableActions>
                          ],
                          // link: ({children}) => (<Link to={"/insider/plans/"+item.uuid}>{children}</Link>)
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default InsiderScripts;
