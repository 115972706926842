import React from 'react';
import styled from 'styled-components';

import {OzCard, OzIcon} from "@ozwol/webui";

import CardHeaderTitle from '../components/CardHeaderTitle';

import {convertNumber} from '../helpers';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;

  display: flex;
  align-items: center;
`;
const Right = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
`;
const Icon = styled.div`
  margin-bottom: -5px;
`;
const Block = styled.div`
  text-align: center;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid #707070;
`;
const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;
  margin-bottom: 2px;
`;
const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #303030;
`;
const BigValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #303030;
`;

const PeriodTotal = ({ numRecharges = null, totRecharges=0, planName, planPrice, isDeletingAccount = false }) => {

  return (
    <OzCard>
      <Wrapper>
        <Left>
          <Icon><OzIcon name={"bill"} size="25px" /></Icon>
          <CardHeaderTitle title="Current period total" />
        </Left>
        <Right>
          <Block>
            <Label>Recharges</Label>
            <Value>({numRecharges}) {convertNumber(totRecharges, 0)} €</Value>
          </Block>
          {!isDeletingAccount ?
            <Block>
              <Label>{planName} plan</Label>
              <Value>{convertNumber(planPrice, 0)} €</Value>
            </Block>
          : null }
          <Block>
            <Label>Total</Label>
            <BigValue>{convertNumber(parseInt(totRecharges) + parseInt(!isDeletingAccount ? planPrice : 0), 0)} €</BigValue>
          </Block>
        </Right>
      </Wrapper>
    </OzCard>
  );
}

export default PeriodTotal;
