import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;

const NoResult = () => {
	return (
    <Wrapper>
      There are no results.
    </Wrapper>
	)
}

export default NoResult;
