import { CHETWODEBLUE } from '@ozwol/webui';
import React  from 'react';
import styled from 'styled-components';

import {OzCard} from "@ozwol/webui";

const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 350px;
  // border-radius: 5px;
  // padding: 20px;
  // color: #ffffff;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // flex-grow: 1;
  // flex-shrink: 1;
  // background-color: ${CHETWODEBLUE};

  text-align: left;
  z-index: 999;

  & > *{
    box-shadow: #c1cce890 0px 3px 2px;
  }
`;

const Close = styled.div`
  // color: #fff;
  cursor: pointer;
  & *{
    font-size: 20px;
  }
`;

const Popup = ({item}) => {
  return (
    <Wrapper>
      <OzCard
        variant={item.variant}
        headerLeft={item.title ? item.title : null}
        headerRight={item.fnClose ? <Close onClick={item.fnClose}><span className="material-symbols-outlined">close</span></Close> : null}
      >
      {item.children}
      </OzCard>
    </Wrapper>
  
  )
}

export default Popup;
