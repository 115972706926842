
import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';


import {OzLoading, OzContainer, OzRow, OzCol, OzCard, OzTable, OzButton} from '@ozwol/webui';

import AccountService from '../../services/AccountService';

import ModalNewPayment from '../../chunks/ModalNewPayment';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import NoResult from '../../components/NoResult';
import PromptedButton from '../../components/PromptedButton';

import {formatError} from '../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(3),
  & table tr td:nth-child(4){
    word-break: break-all;
    white-space: unset;
  }
`;
const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *{
    margin-left: 5px;
  }
`;
const UnselectedDefault = styled.span`
  color: #dedede;
`;


const PaymentMethods = ({refScrollerPage}) => {
  const {overlay, setOverlay} = useContext(OverlayContext);


  const [list, setList] = useState(null);
  const [currentDefault, setCurrentDefault] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  

  const getList = useCallback(() => {
    AccountService.listPaymentMethods().then((response) => {
      if(response && response.data){
        setList(response.data);
      }
    }).catch((error) => {
      setList(null);
      setFetchError(formatError(error));
    });
    AccountService.getDefaultPaymentMethod().then((response) => {
      setCurrentDefault(response.default_payment_method);
    }).catch((error) => {
      setCurrentDefault(null);
      setFetchError(formatError(error));
    });
  }, []);

  useEffect(() => {
    if(!overlay){
      getList();
    }
  }, [getList, overlay]);
  


  const setDefaultPaymentMethod = useCallback((id) => {
    setCurrentDefault(null);
    AccountService.setDefaultPaymentMethod(id).then((response) => {
      AccountService.getDefaultPaymentMethod().then((response) => {
        setCurrentDefault(response.default_payment_method);
      }).catch((error) => {
        setCurrentDefault(null);
        setFetchError(formatError(error));
      });
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, []); //getPayments

  const deleteItem = useCallback((payment) => {
    setList(null);
    AccountService.deletePaymentMethod(payment.id).then((response) => {
      getList();
    }).catch((error) => {
      setList(null);
      setFetchError(formatError(error));
    });
  }, [getList]);

  return (
    <>
      <Meta title={"Payment Methods"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="account_balance"
          breadcrumbsText={["Payment Methods", "Account"]}
          refScrollerPage={refScrollerPage}
        />
        
        <OzRow>
          <OzCol>
            <OzCard
              headerLeft={
                <CardHeaderTitle title={"Payment methods"} />
              }
              headerSeparator={true}
              footerRight={<OzButton variant={"chetwodeblue"} onClick={() => setOverlay(<ModalNewPayment />)}>Add payment method</OzButton>}
            >

              <OzRow>
                <OzCol widthmd="12">
                  {fetchError ?
                    <ErrorMessage>{fetchError}</ErrorMessage>
                  : list ?
                    list.length > 0 ?
                      <>
                        <TableWrapper>
                          {/* <Elements stripe={stripePromise}> */}
                            
                            <OzTable
                              columns={[
                                <center>Default</center>,
                                <center>Method</center>,
                                "Owner",
                                "Number",
                                "Expires",
                                ""
                              ]}
                              values={list.map((payment, i) => ({values: [
                                <center>
                                  {payment.id === currentDefault ?
                                    <span className="material-symbols-outlined">star</span>
                                  :
                                    <UnselectedDefault onClick={() => setDefaultPaymentMethod(payment.id)} style={{"cursor": "pointer"}}>
                                      <span className="material-symbols-outlined">star</span>
                                    </UnselectedDefault>
                                  }
                                </center>,
                                <center>
                                  {payment.type === "card" ?
                                    <span className="material-symbols-outlined">credit_card</span>
                                  : null }
                                  {payment.type === "sepa_debit" ?
                                    <span className="material-symbols-outlined">account_balance</span>
                                  : null }
                                </center>,
                                payment.billing_details.name,
                                <>
                                  {payment.type === "card" ?
                                    "**** **** ****" + payment.card.last4 + " (" + payment.card.brand.toUpperCase() + ")"
                                  : null }
                                  {payment.type === "sepa_debit" ?
                                    payment.sepa_debit.country + "*********************" + payment.sepa_debit.last4
                                  : null }
                                </>,
                                <>
                                  {payment.type === "card" ?
                                    payment.card.exp_month + "/" + payment.card.exp_year
                                  : null }
                                  {payment.type === "sepa_debit" ?
                                    "-"
                                  : null }
                                </>,
                                <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                  {list.length > 1 && payment.id !== currentDefault?
                                    <>
                                      <PromptedButton 
                                        buttonVariant="push-bittersweet" 
                                        buttonSize={"small"} 
                                        buttonText={"Delete"} 
                                        prompt="DELETE"
                                        onConfirm={() => deleteItem(payment)} 
                                      />    
                                    </>
                                  : null }
                                </TableActions>
                              ]}))}
                            />
                          {/* </Elements> */}
                        </TableWrapper>

                      </>
                    : <NoResult />
                  : <OzLoading bgcolor={"#ffffff"} />}

                </OzCol>
              </OzRow>
              <ErrorMessage>
                {errorMessage}
              </ErrorMessage>

            </OzCard>
          </OzCol>
        </OzRow>

      </OzContainer>
    </>
  )
}

export default PaymentMethods;
