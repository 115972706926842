import React, {useState, useCallback, useContext} from 'react';
import styled from 'styled-components';
import { getFormattedDate } from "../../helpers";
import {OverlayContext} from './../../contexts/OverlayContext';
import {CDN} from './../../config';

import {OzContainer, OzCard, OzButton, OzRow, OzCol, OzInput, OzTypo, CHETWODEBLUE} from '@ozwol/webui';

import AccountService from '../../services/AccountService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';

import {formatError} from '../../helpers';


const Note = styled.div`
  color: ${CHETWODEBLUE};
  background: ${CHETWODEBLUE}29;
  padding: 25px;
  border-radius: 5px;
`;



const ProfileModalClose = ({
  period,
  user,
  fnSuccess
}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const close = useCallback(() => {
    AccountService.closeAccount().then((response) => {
      fnSuccess();
      setRequestSent(true);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [fnSuccess]);

  return (
    
    <OzContainer size="medium">
      <HealthyWrapper loading={!period}>
        {!requestSent ? 
          <OzCard
            headerLeft={
              <CardHeaderTitle title={"Close account"} />
            }
            footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Cancel</OzButton>}
            footerRight={<OzButton onClick={() => close()} variant={"bittersweet"} disabled={disabled}>CLOSE</OzButton>}
          >
            <OzRow>
              <OzCol widthmd="12">
                <br/>
                {period.currentPlan.planType === "PAID" ?
                  "Your account will be closed at the end of the current period ("+getFormattedDate(period.endDate)+"). Until that date you can use Ozwol as usual."
                :
                  "Your account will be closed now."
                }
                
                <br/>
                <br/>
                <OzInput
                  label="To confirm the deletion of your account, type 'CLOSE' in the box"
                  width="100%"
                  value={text}
                  highlighted={text && text.length > 0}
                  placeholder="CLOSE"
                  onChange={(val) => {
                      setText(val);
                      val === "CLOSE" ? setDisabled(false) : setDisabled(true)
                  }}
                />
                <br/>
                <br/>
                <Note>
                By closing your Ozwol account all <b>assets</b>, <b>settings</b> and <b>configurations</b> will be deleted at the end of the current period.
                </Note>
              </OzCol>
            </OzRow>
            <ErrorMessage>
              {errorMessage}
            </ErrorMessage>
          </OzCard>
        :
          <OzCard>
            <center>
              <OzTypo
                variant={"h1c"}
                color={CHETWODEBLUE}
              >Your account will be deleted on {getFormattedDate(period.endDate)}</OzTypo>
              <br/>
              <br/>
              <img alt="close account" src={CDN+"console-assets/the-end.svg"} height="250px" />
              <br/>
              <br/>
              <br/>
              <OzButton onClick={() => setOverlay(null)} variant={"chetwodeblue"}>Finish</OzButton>
            </center>
          </OzCard>
        }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default ProfileModalClose;
