import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {OzBreadcrumbs, OzContainer, CHETWODEBLUE, BITTERSWEET} from '@ozwol/webui';

import Meta from '../../components/Meta';

import changelog from "./../../changelog.txt";


const Content = styled.div`

`;

const Section = styled.div`
  color: ${BITTERSWEET};
  margin-top: 60px;
  margin-bottom: -10px;
  font-size: 20px;

  &:first-child{
    margin-top: 0px;
  }
`;
const Version = styled.div`
  color: ${CHETWODEBLUE};
  margin-top: 30px;
  font-size: 15px;
  font-weight: bold;

`;
const Title = styled.div`
  color: ${CHETWODEBLUE};
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;

`;
const Item = styled.div`
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-size: 12px;

`;
const Text = styled.div`
  color: black;
  font-size: 12px;
`;
const Note = styled.div`
  color: black;
  font-size: 10px;
`;

const Changelog = () => {
  const [lines, setLines] = useState([]);

  useEffect(() => {
    fetch(changelog).then((r) => r.text()).then(text  => {
      setLines(text.split('\n'));
    })
  }, []);
  return (
    <OzContainer size="extra">
      <Meta title={"Changelog"} />
      <OzBreadcrumbs values={[
        {
          name: "Changelog",
          icon: "dashboard"
        },{
          name: "Overall"
        }
      ]} />
      <br/>
      <br/>

      <Content>
        {lines.map((line, i) => {
          if(line.indexOf("!") > -1){
            if(process.env.REACT_APP_ENV === "prod"){
              return "";
            }else{
              line = line.replace("!","");
            }
          }
          return line.indexOf("### ") > -1 ?
            <Title key={i}>{line.replace("### ","")}</Title>
          : line.indexOf("## ") > -1 ?
            <Version key={i}>{line.replace("## ","")}</Version>
          : line.indexOf("# ") > -1 ?
            <Section key={i}>{line.replace("# ","")}</Section>
          : line.indexOf("* ") > -1 ?
            <Item key={i}>{line.replace("* ","")}</Item>
          : line.indexOf("° ") > -1 ?
            <Note key={i}>{line.replace("° ","")}</Note>
          :
            <Text key={i}>{line}</Text>
        })}
      </Content>

    </OzContainer>
  )
}

export default Changelog;
