import React, {useState, useEffect, useContext}  from 'react';
// import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {OverlayContext} from './../../contexts/OverlayContext';
import {CognitoContext} from '../../contexts/CognitoContext';

import AccountService from './../../services/AccountService';
import VersionService from './../../services/VersionService';

import ErrorMessage from '../../components/ErrorMessage';

import {CHETWODEBLUE, GHOSTWHITE, CHARCOAL, OzCard, OzContainer, OzIcon, OzButton} from "@ozwol/webui";

import {getFormattedDateTime} from './../../helpers';

const Wrapper = styled.footer`
  text-align: center;
  color: #a5a5a5;
  font-size: 13px;
`;

// const Nav = styled(Link)`
//   color: inherit;

//   font-weight: 400;

//   &:hover{
//     text-decoration: underline;
//     color: ${CHARCOAL};
//   }
// `;

const Anchor = styled.span`
  cursor: pointer;

  &:hover {
    color: ${CHARCOAL};
  }
`;
const Close = styled.div`
  cursor: pointer;
`;

const IubendaWrapper = styled.span`
  display: block;
  max-height: 55vh;
  overflow: scroll;
  margin-bottom: 20px;
  
  

  & *{
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
    font-variant: normal !important;


    text-align: left !important;
  }

  & bold, 
  & b{
    font-weight: 600 !important;
  }

  & p, 
  & li{
    font-size: 12px !important;
    color: #303030 !important;
    line-height: 18px !important;
  }

  & .iubenda-button{
    display: none !important;
    opacity: 0 !important;
  }

  & .iub_content,
  & .iub_container,
  & .iub_footer {
    margin: 0px !important;
    padding: 0px !important;
  }

  & h1,
  & h1 strong{
    color: #00141F !important;
    font-size: 20px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
    color: ${CHETWODEBLUE} !important;

  }
  & h2{
    color: #00141F !important;
    font-size: 20px !important;
    line-height: 28px !important;
    font-weight: 700 !important;
    margin-bottom: 0px !important;
    color: ${CHETWODEBLUE} !important;
  }
  & h3{

  }

  & .box_primary{
    background: ${GHOSTWHITE} !important; 
  }
`;

const Versions = styled.div`
  font-size: 10px;
  margin-top: 3px;
`;


const Footer = () => {
  const consoleVersion = require('./../../../package.json').version;
  
  
  const {setOverlay} = useContext(OverlayContext);
  const {setImportantOverlay} = useContext(OverlayContext);
  // const version = require('./../../../package.json').version;
  const [showIubenda, toggleIubenda] = useState(false);
  const {cognito} = useContext(CognitoContext);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [version, setVersion] = useState(null);


  useEffect(() => {
    if(!cognito.groups.includes("OzwolCoworker")){
      AccountService.getUser().then((response) => {
        setUser(response);
      }).catch((error) => {
        setError("There was an error when loading data.");
      }); 
    }
    VersionService.getVersion().then((response) => {
      setVersion(response.result);
    }).catch((error) => {
      setError("There was an error when loading data.");
    }); 
  }, [cognito]);


  useEffect(() => {
    if(showIubenda){
      let s = document.createElement("script");
      let tag = document.getElementsByTagName("script")[0];
      s.src="https://cdn.iubenda.com/iubenda.js"; 
      tag.parentNode.insertBefore(s,tag); 
    }
  }, [showIubenda]);

  
  useEffect(() => {
    if(!localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))){
      if(user){
        if(!user.contractAcceptanceDatetime) { //check su data in futuro
          setImportantOverlay(
            <OzContainer size="medium">
              <OzCard
                headerLeft={"Terms of service"}
                headerSeparator={true}
                footerRight={!error ? <OzButton variant={"chetwodeblue"} onClick={() => {
                  AccountService.updateUser({
                    contractAcceptanceDatetime: new Date()
                  }).then((response) => {
                    setImportantOverlay();  
                  }).catch((error) => {
                    setError("Something went wrong.");
                  });
                }}>Accept and continue</OzButton> : null}
                footerLeft={!error ? <OzButton variant={"bittersweet-o"} onClick={() => {
                  AccountService.updateUser({
                    
                  }).then((response) => {
                    setImportantOverlay();  
                    window.location.replace("/auth/login");
                  }).catch((error) => {
                    setError("Something went wrong.");
                  });
                }}>Deny, my projects will be disabled</OzButton> : null}          
                footerCenter={
                  <ErrorMessage>{error}</ErrorMessage>
                }
              >
                <IubendaWrapper>
                  <a href="https://www.iubenda.com/termini-e-condizioni/45657269" className="iubenda-button iubenda-embed iub-body-embed" title="Terms of service">Terms of service</a>
                </IubendaWrapper>
              </OzCard>
            </OzContainer>          
          );
          toggleIubenda(true);
        } 
      }
    }
  }, [user, error, setImportantOverlay]);

  return (
    <Wrapper>
      <OzContainer size="extra">
        <Anchor onClick={() => {
          setOverlay(
            <OzContainer size="medium">
              <OzCard
                headerLeft={"Terms of service"}
                headerSeparator={true}
                headerRight={<Close onClick={() => {setOverlay(false); toggleIubenda(false);}}><OzIcon name="x"/></Close>}
                footerLeft={user ?  "Your contract acceptance date: " + getFormattedDateTime(user.contractAcceptanceDatetime) : null}
              >
                <IubendaWrapper>
                  <a href="https://www.iubenda.com/termini-e-condizioni/45657269" className="iubenda-button iubenda-embed iub-body-embed" title="Terms of service">Terms of service</a>
                </IubendaWrapper>
              </OzCard>
            </OzContainer>          
          );
          toggleIubenda(true);
        }}>Terms of service</Anchor> &nbsp; <Anchor onClick={() => {
          setOverlay(
            <OzContainer size="medium">
              <OzCard
                headerLeft={"Privacy Policy"}
                headerSeparator={true}
                headerRight={<Close onClick={() => {setOverlay(false); toggleIubenda(false);}}><OzIcon name="x"/></Close>}
              >
                <IubendaWrapper>
                  <a href="https://www.iubenda.com/privacy-policy/45657269" className="iubenda-button iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a>
                </IubendaWrapper>
              </OzCard>
            </OzContainer>    
          );
          toggleIubenda(true);
        }}>Privacy policy</Anchor> &nbsp; Ozwol Technologies<br/>
        <Versions>Console V. {consoleVersion}{version && version.platform_version ? " - Platform V. "+version.platform_version : null}{version && version.engine_version ? " - Engine V. "+version.engine_version : null}</Versions>
      </OzContainer>
    </Wrapper>
  )
}

export default Footer;
