import React, {useState, useEffect} from 'react';
// import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {GHOSTWHITE, SILVERTREE, BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzIcon, OzTable, OzChartHistogram} from '@ozwol/webui';

import Meta from '../../components/Meta';
import MonthlySales from '../../chunks/MonthlySales';

import UsersService from '../../services/UsersService';
import ProjectsService from '../../services/ProjectsService';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';

import {formatError} from '../../helpers';
const MONTHS = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"]



const CardGroup = styled.div`
  &>*:not(:first-child){
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 3px solid ${GHOSTWHITE};
  }
  &>*:not(:last-child){
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;


const CardPhrase = styled.div`

  display: flex;
  align-items: center;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;

  display: flex;
  align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
`;



const InsiderEconomics = ({refScrollerPage}) => {

  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);

  const [fetchError, setFetchError] = useState(false);

  // const scrollToCredits = useRef();

  useEffect(() => {
    ProjectsService.adminList().then((response) => {
      setProjects(response.result.slice(0,10));
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    UsersService.adminList().then((response) => {
      setUsers(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    
  }, []);


  return (
    <OzContainer size="extra">
      <Meta title={"Economics"} />
      <PageHeader
        breadcrumbsIcon="price_change"
        breadcrumbsText={["Economics"]}
        refScrollerPage={refScrollerPage}
        // anchors={[
        //   {
        //     "icon": "toll",
        //     "ref": scrollToCredits
        //   }
        // ]}
      />
      <HealthyWrapper error={fetchError} loading={!projects || !users}>
        {projects && users ?
          <OzRow>
            <OzCol>
              <MonthlySales 
                totPlans={5093}
                totRecharges={30000}
              />
            </OzCol>
            <OzCol>
              <CardGroup>
                <OzCard
                  height="100%"
                  headerLeft={<CardHeaderTitle title="Monthly overview of sold plans" />}
                  headerRight={
                    <CardPhrase>
                      <CardPhraseText>
                        Totale piani e ricariche questo mese
                        <CardPhraseSuperNumber>
                          2.093
                        </CardPhraseSuperNumber>
                      </CardPhraseText>
                      <OzIcon name={"assets"} size="25px" />
                    </CardPhrase>
                  }
                >

                  <OzChartHistogram
                    stretch={false}
                    fnCallback={(ret) => alert(JSON.stringify(ret))}
                    values={[...new Array(12*3)].map((nothing, i) => ({
                      id: i,
                      label: MONTHS[Math.floor(i/3)] + " '2" + (i%3),
                      bars: [{
                        id: i,
                        value: Math.floor(Math.random() * (100 + 50 + 1) + 50) * ((i%3)+1),
                        style: {
                          barColor: i%3 === 0  ? "yellow": i%3 === 1 ? "pink" : SILVERTREE
                        }
                      }]
                    }))}
                  />

                </OzCard>
                <OzCard
                  height="100%"
                >                  
                  <OzTable
                    columns={[
                      "Customer",
                      "Period",
                      "Plan",
                      "Price",
                      "",
                      "Recharges",
                      "Total",
                      "",
                      "Period cost"
                    ]}
                    values={users.map((item, i) => (({values: [
                      item.company,
                      "26/05/2021 - 26/06/2021",
                      item.currentPlan.name,
                      item.currentPlan.price + " €",
                      <OzIcon name={"arrow-down"} size="20px" color={BITTERSWEET} />,
                      2,
                      (parseInt(item.currentPlan.rechargePrice)*2) + " €",
                      <OzIcon name={"arrow-up"} size="20px" color={SILVERTREE} />,
                      (parseInt(item.currentPlan.price) + (parseInt(item.currentPlan.rechargePrice)*2)) + " €"
                    ]})))}
                  />
                </OzCard>
              </CardGroup>
            </OzCol>
          </OzRow>
        : null }
      </HealthyWrapper>
    </OzContainer>
  )
}

export default InsiderEconomics;
