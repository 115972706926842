import React, {useState, useContext, useCallback} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../contexts/OverlayContext';

import {OzButton, OzCard, OzInput} from '@ozwol/webui';

import CognitoService from '../services/CognitoService';

import ModalMFA2 from './ModalMFA2';
import MfaWizard from './../pages/account/common/MfaWizard';

import CardHeaderTitle from '../components/CardHeaderTitle';
import ErrorMessage from '../components/ErrorMessage';

// import {formatError} from '../helpers';

const Container = styled.div`
  width: 450px;
`;


const ModalMFA1 = ({user, onSuccess}) => {
  const {setOverlay} = useContext(OverlayContext);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const verifyPass = useCallback(() => {
    setErrorMessage(null);
    CognitoService.checkPassword(password).then((response) => {
      setOverlay(<ModalMFA2 user={user} onSuccess={onSuccess} />)
    }).catch((error) => {
      setErrorMessage("Wrong password.");
    });
  }, [user, password, setOverlay, onSuccess]); /* item, setOverlay */
  
  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle 
            title={"Verify your identity"} 
          />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => setOverlay(null)}>Cancel</OzButton>}
        footerRight={<OzButton variant={"chetwodeblue-o"} onClick={() => verifyPass()}>Next</OzButton>}
      >
        Install a compatible app such as Google Authenticator, Authy or a similar.<br/>
        Start by entering your password to confirm your identity. Then, we will guide you through two additional simple steps.

        <br/>
        <OzInput
          label="Password"
          type="password"
          toggleVisibility
          width="100%"
          value={password ? password : ""}
          onChange={(val) => {
            setPassword(val);
          }}
          required
        />

        <br/>
        <MfaWizard step={1} />

        <ErrorMessage noMargin>
          {errorMessage}
        </ErrorMessage>

      </OzCard>

    </Container>
  );

}

export default ModalMFA1;
